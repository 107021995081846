import { useEffect, useState } from "react";
import SideBar from "../../../components/sidebars/settings"
import { ToggleWithText, CheckBox } from "components/UI";
import { getPrivacySettings, updatePrivacySettings } from "../../../apis/settings";
import downArrow from "../../../assets/settings/icons/downArrow.svg"
import { toast } from "react-toastify"
import { Link } from "react-router-dom";
import { CONVERSATION } from "shared/router";


function Index() {

    const [privacySettings, setPrivacySettings] = useState({
        hide_my_name: false,
        turn_off_discoverability: false,
        who_can_schedule_meetings: "",
    })
    const [access, setAccess] = useState([])

    const [visible, setVisible] = useState({
        who_can_schedule_meeting: false
    })

    const fetchPrivacySettings = async () => {
        const res = await getPrivacySettings()

        setPrivacySettings(res?.data?.privacy)
        setAccess(res?.access_list)
    }

    const handleSaveBtn = async () => {
        const toastId = toast.loading("Updating Privacy settings...")
        await updatePrivacySettings(privacySettings)
        toast.dismiss(toastId)
    }

    const updatePrivacySettingsState = (key, value) => {
        const setting = access?.find(s => s?.name === key);
        if (setting?.value?.value) {
            setPrivacySettings(prev => ({
                ...prev,
                [key]: value
            }))
        } else {
            toast.error("Sorry You don't have the access to make the change")
        }
    }

    useEffect(() => {
        fetchPrivacySettings()
    }, [])

    return (
        <div>
            <div className="flex flex-col h-full min-h-screen">

                <div className="flex flex-col w-full text-black rounded-md lg:w-10/12">
                    <h1 className="mx-auto mt-10 text-2xl font-semibold">Privacy settings</h1>
                    <div className="flex-1 w-full p-4 overflow-auto lg:m-10 lg:ms-14">
                        <div className="rounded-md lg:w-[700px]">
                            <div className="p-10 ">
                                <h1 className="text-lg font-semibold">Personal</h1>

                                <div className="w-fit md:w-[600px] my-5">
                                    <ToggleWithText
                                        mainTextAndColor={["Hide My Name", 'black']}
                                        subTextAndColor={["This will hide your name from others", 'gray-500']}
                                        isChecked={privacySettings?.hide_my_name}
                                        onToggle={() => updatePrivacySettingsState("hide_my_name", !privacySettings?.hide_my_name)}
                                    />
                                </div>
                                <h1 className="text-lg font-semibold">Discoverability</h1>
                                <div className="w-fit md:w-[600px]  my-5">
                                    <ToggleWithText
                                        mainTextAndColor={["Profile Visibility", 'black']}
                                        subTextAndColor={["This will hide your profile from others", 'gray-500']}
                                        isChecked={privacySettings?.turn_off_discoverability}
                                        onToggle={() => updatePrivacySettingsState("turn_off_discoverability", !privacySettings?.turn_off_discoverability)}
                                    />
                                </div>
                                <div className="w-fit md:w-[600px]">
                                    <div className="">
                                        <h1 className="text-lg">Meeting Scheduling</h1>
                                        <div className="flex justify-between w-full">
                                            <p className="w-[70%] mt-1 font-normal text-sm text-gray-500">Manage your Meeting slots</p>
                                            <Link to={CONVERSATION.MEETING_SLOTS}>
                                                <img src={downArrow} alt="" className="-rotate-90 cursor-pointer" />
                                            </Link>

                                        </div>
                                    </div>
                                </div>

                                {/* <div className="w-fit md:w-[600px]">
                                    <div className="flex items-start justify-between">
                                        <div>
                                            <h1 className="text-lg">Meeting Scheduling</h1>
                                            <p className="w-[67%] mt-1 font-normal text-sm text-gray-500">Specify who can schedule meeting with you (Nobody,Friends Only)</p>
                                        </div>
                                        <div className="flex flex-col items-end">
                                            <img src={downArrow} alt="" className="cursor-pointer" onClick={() => setVisible(prev => ({ ...prev, who_can_schedule_meeting: !prev.who_can_schedule_meeting }))} />
                                            {visible.who_can_schedule_meeting &&
                                                <div className="flex flex-col mt-2">
                                                    <CheckBox label={"Friends"} isChecked={privacySettings?.who_can_schedule_meetings === 'friends' ? true : false} handleOnChange={() => updatePrivacySettingsState("who_can_schedule_meetings", "friends")} />
                                                    <CheckBox label={"No One"} isChecked={privacySettings?.who_can_schedule_meetings === 'no_body' ? true : false} handleOnChange={() => updatePrivacySettingsState("who_can_schedule_meetings", "no_body")} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div> */}

                            </div>

                            <div className='h-[70px] mt-16 ms-10 w-[80%] md:w-[50%]'>
                                <button className='w-full h-[60px] bg-brandRed flex justify-center items-center text-white cursor-pointer' onClick={handleSaveBtn}>
                                    Save
                                </button>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Index
