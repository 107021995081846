import logo from "assets/signup/signup-logo.svg"
import flag from "assets/signup/India (IN).svg"
import { useState } from "react"
import { sendOtp, signup } from "apis/signup"
import { phoneValidation, signupValidation } from "utils/validations/signup"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import * as Yup from 'yup';
import { Success } from "./success"
import ContentCard from "components/Cards/loginAndSignInContent"
import { AUTH } from "shared/router"
import { Helmet } from "react-helmet"
import ReactPixel from 'react-facebook-pixel';


const mobile = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Auth/Sign_Up/hl6htf2tvzs1pqjpcuor`
const mobile2 = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Auth/Sign_Up/hngf5vd6fhnr5fjlukaf`
const mobile3 = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Auth/Sign_Up/drcghrnlxqqx2touzfpw`

function Index() {

    const slideImage = [
        {
            src: mobile,
            mainText: 'More Categories,More Events',
            text: "Couple Squad is excited to share that we've expanded our event options! We now offer 8 different categories of events and over 20+ specific events for both Singles and Couples."
        },
        {
            src: mobile2,
            mainText: 'Interest-Specific Events For Everyone',
            text: "Whether you are a single or a couple we have all kinds of events for everyone, from outdoor adventures to professional networking, fitness and wellness activities, community service events, and much more."
        },
        {
            src: mobile3,
            mainText: 'Pre-Engagement with the Event Attendees',
            text: "Couple Squad offers a unique feature that allows you to view the list of event attendees before joining the event. Get ready to have a great time at the event!"
        }
    ]

    const [isVerifyBtn, setIsVerifyBtn] = useState(true)

    const [data, setData] = useState({
        phone: '',
        email: '',
        ref_code: '',
        otp: ''
    })
    const [otp, setOtp] = useState(false)
    const [success, setSuccess] = useState(false)
    const [timer, setTimer] = useState(30);

    const handleSubmit = async () => {
        try {
            const toastId = toast.loading("verifying data please wait...")
            if (otp) {
                await signupValidation.validate(data, { abortEarly: false });
                const res = await signup(data)
                if (res.success) {

                    ReactPixel.trackCustom('Sign_Up', {
                        buttonName: 'signUp',
                    });

                    localStorage.setItem('session', res.data)
                    setSuccess(true)
                }
            } else {
                toast.error('Please verify phone No');
            }
            toast.dismiss(toastId)
        } catch (error) {
            toast.dismiss()
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const handleSendOtp = async () => {
        try {
            const toastId = toast.loading("sending OTP please wait...")
            await phoneValidation.validate(data, { abortEarly: false });
            const apiBody = { phone: data?.phone, email: data?.email || null }
            const res = await sendOtp(apiBody);
            if (res?.success) {
                setIsVerifyBtn(false)
                setOtp(true);
                setTimer(30);

                const countdown = setInterval(() => {
                    setTimer(prevTimer => {
                        if (prevTimer > 1) {
                            return prevTimer - 1;
                        } else {
                            clearInterval(countdown);
                            return 0;
                        }
                    });
                }, 1000);
            }
            toast.dismiss(toastId)
        } catch (error) {
            toast.dismiss()
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    };


    return (
        <div className='flex flex-col w-full h-full bg-white lg:flex-row'>
            <Helmet>
                <meta name="title" content="Signup for Couple Squad | Download the app now" />
                <meta name="description" content="Sign up for Couple Squad. Get the app from the Play Store or App Store. Join the community to find exclusive events and connect with like-minded people" />

                <script>
                    {`
                gtag('event', 'conversion', {
                    'send_to': 'AW-11458368799/Lfl1CIv4w-sZEJ-q49cq',
                    'value': 1.0,
                    'currency': 'INR'
                });
                `}
                </script>

            </Helmet>

            <div className='flex flex-col hidden w-full lg:block max-h-1/2 max-lg:h-screen lg:w-1/2 bg-brandRed lg:items-start md:items-center'>
                <ContentCard slides={slideImage} />
            </div>

            {
                success ?
                    <Success />
                    :


                    <div className="flex flex-col items-center justify-center w-full h-screen text-black bg-white lg:w-1/2">

                        <img src={logo} alt="Descriptive Alt Text" className="hidden w-40 h-40 md::block" />

                        <h2 className="mb-2 text-5xl font-semibold">Sign up</h2>
                        <p className="w-8/12 mb-6 text-center">Enter your email and password to access your account</p>

                        <input type="email" placeholder="Email" className="w-[90%] md:w-6/12 px-4 py-2 mb-4 bg-white border"
                            onChange={(e) => setData(prev => ({ ...prev, email: e.target.value }))}
                        />

                        <div className="w-[90%] relative flex items-center md:w-6/12 mb-4 border">
                            <span className="absolute left-0 flex items-center">
                                <img src={flag} alt="IN" className="w-8 h-5" />
                                +91
                            </span>
                            <input type="text" maxLength="10" placeholder="Enter Mobile Number" className="w-full px-4 py-2 bg-white border-none ms-16 focus:outline-none"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        setData(prev => ({ ...prev, phone: value }));
                                    }
                                }} />

                            {data.phone.length === 10 && isVerifyBtn && (
                                <button
                                    onClick={handleSendOtp}
                                    className="absolute px-4 py-1 text-sm text-green-500 transform -translate-y-1/2 bg-white border border-green-500 right-2 top-1/2"
                                >
                                    Verify
                                </button>
                            )}
                        </div>


                        {otp && (
                            <div className="w-[90%] relative md:w-6/12 mb-4">
                                <input type="text" maxLength="6" placeholder="Verification Code" className="w-full px-4 py-2 bg-white border"
                                    onChange={(e) => setData(prev => ({ ...prev, otp: e.target.value }))} />

                                <div className="flex items-center justify-center mt-2">
                                    {timer > 0 ? (
                                        <span>Time left: {timer}s</span>
                                    ) : (
                                        <button
                                            className="text-blue-500" onClick={handleSendOtp}>
                                            Resend OTP
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}



                        <input type="text" placeholder="Referral Code" className="w-[90%] md:w-6/12 px-4 py-2 mb-4 bg-white border"
                            onChange={(e) => setData(prev => ({ ...prev, ref_code: e.target.value }))}
                        />

                        <button name="signUp" type="submit" onClick={handleSubmit} value="Submit" className="mb-4 p-4 bg-[#f83758] text-white mt-5 w-[90%] md:w-6/12" >Submit</button>
                        <p className="mb-4">Already have an account? <Link to={AUTH.LOGIN} className="text-blue-500">Log in</Link></p>
                        {/* <button className="flex items-center w-6/12 px-4 py-2 mb-10 text-white border rounded md:mb-0">
                            <img src={googleIcon} alt="Google Icon" className="w-full h-6 mr-2" />
                        </button> */}
                    </div>
            }
        </div >
    )
}

export default Index