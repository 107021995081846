
import back_btn_icon from "../../../assets/onBoarding/back-btn-icon.svg"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { genderValidation } from "../../../utils/validations/onBoarding";
import { toast } from "react-toastify";
import * as Yup from 'yup'
import { updateGender } from "apis/onBoarding";

const bg_img = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Auth/Onboarding/udancnqtd33uzyizsc3o`

function Index() {

    const navigate = useNavigate()
    const [data, setData] = useState({
        gender: '',
    });

    useEffect(() => {
        const serializedObject = localStorage.getItem('userData');
        const userData = JSON.parse(serializedObject);

        if (userData?.onBoardingTwo) {
            navigate('/on-boarding-3')
        } else if ((userData?.data?.dob) && (userData?.data?.gender)) {
            setData({ gender: userData.data.gender })
        }

    }, [])


    const handleLetsGo = async () => {
        try {
            await genderValidation.validate(data, { abortEarly: false });
            await updateGender(data)
            const serializedObject = localStorage.getItem('userData');
            const currentUserData = JSON.parse(serializedObject) || {};
            const updatedUserData = {
                ...currentUserData,
                onBoardingTwo: true,
                data: {
                    ...currentUserData.data,
                    gender: data.gender,
                },
            };
            localStorage.setItem('userData', JSON.stringify(updatedUserData));
            navigate('/on-boarding-3');
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    const handleBackBtn = () => {
        const serializedObject = localStorage.getItem('userData');
        const currentUserData = JSON.parse(serializedObject) || {};
        const updatedUserData = {
            ...currentUserData,
            onBoardingOne: false,
        };
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        navigate('/on-boarding-1')
    }



    return (
        <div className="flex flex-col min-h-screen bg-white lg:flex-row">
            <div className="relative flex-1 hidden overflow-hidden bg-center bg-cover lg:block lg:h-auto h-1/2 lg:w-full">
                <img src={bg_img} alt="" className="object-cover w-full h-screen" />

                <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-50 text-start">
                    <h1 className="p-4 font-semibold text-7xl">Date- Marry-Stay <br />in Love</h1>
                    <p className="p-4">We live by our motto, Date-marry-stay in love. Join Couple Squad <br /> to find your perfect match and commit to a lifetime of happiness.</p>
                </div>
            </div>


            <div className="relative flex flex-col items-center justify-center flex-1 w-full h-screen mx-auto my-10 bg-white lg:h-auto lg:w-6/12 lg:my-0 text-brandRed ">

                <img src={back_btn_icon} alt="back" onClick={handleBackBtn} className="absolute top-0 left-0 p-2 mt-5 ml-5 text-sm text-white cursor-pointer" />

                <div className="w-[90%] mx-auto mb-5 md:w-7/12 lg:mb-0">
                    <h1 className="mt-10 mb-5 text-lg font-semibold text-black">I am a</h1>


                    <div className="flex flex-col w-full space-y-4 md:w-10/12">
                        {/* Male button */}
                        <label
                            className={`flex justify-between items-center p-3 cursor-pointer w-full ${data.gender === 'male' ? 'bg-brandRed text-white' : 'bg-gray-200 text-black'}`}
                            onClick={() => setData(prev => ({ ...prev, gender: 'male' }))}>
                            <div className="flex items-center">
                                {/* Male Icon */}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.25 0H13.75C13.5511 0 13.3603 0.0790178 13.2196 0.21967C13.079 0.360322 13 0.551088 13 0.75C13 0.948912 13.079 1.13968 13.2196 1.28033C13.3603 1.42098 13.5511 1.5 13.75 1.5H16.4397L12.4956 5.44406C11.0058 4.22632 9.10502 3.62773 7.1863 3.77209C5.26758 3.91646 3.47774 4.79275 2.18696 6.21971C0.896182 7.64667 0.203213 9.51515 0.251376 11.4387C0.299539 13.3622 1.08515 15.1937 2.44573 16.5543C3.8063 17.9148 5.63775 18.7004 7.56129 18.7486C9.48483 18.7968 11.3533 18.1038 12.7803 16.813C14.2072 15.5222 15.0835 13.7324 15.2279 11.8137C15.3723 9.89496 14.7737 7.99415 13.5559 6.50437L17.5 2.56125V5.25C17.5 5.44891 17.579 5.63968 17.7196 5.78033C17.8603 5.92098 18.0511 6 18.25 6C18.4489 6 18.6397 5.92098 18.7803 5.78033C18.921 5.63968 19 5.44891 19 5.25V0.75C19 0.551088 18.921 0.360322 18.7803 0.21967C18.6397 0.0790178 18.4489 0 18.25 0ZM11.9912 15.4959C11.152 16.3348 10.0829 16.906 8.91909 17.1373C7.75528 17.3685 6.54902 17.2496 5.45282 16.7954C4.35662 16.3412 3.41971 15.5721 2.76054 14.5855C2.10137 13.5988 1.74954 12.4389 1.74954 11.2523C1.74954 10.0658 2.10137 8.90584 2.76054 7.9192C3.41971 6.93257 4.35662 6.16353 5.45282 5.70932C6.54902 5.25512 7.75528 5.13614 8.91909 5.36743C10.0829 5.59873 11.152 6.1699 11.9912 7.00875C13.1147 8.13526 13.7457 9.66134 13.7457 11.2523C13.7457 12.8433 13.1147 14.3694 11.9912 15.4959Z" fill={`${data.gender === 'male' ? 'white' : 'black'}`} />
                                </svg>
                                <span>Male</span>
                            </div>
                            {data.gender === 'male' && (
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                            )}
                        </label>

                        {/* Female button */}
                        <label
                            className={`flex justify-between items-center p-3 cursor-pointer w-full ${data.gender === 'female' ? 'bg-brandRed text-white' : 'bg-gray-200 text-black'}`}
                            onClick={() => setData(prev => ({ ...prev, gender: 'female' }))}>
                            <div className="flex items-center">
                                {/* Female Icon */}

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.91667 12.8333C10.1844 12.8333 12.8333 10.1844 12.8333 6.91667C12.8333 3.64898 10.1844 1 6.91667 1C3.64898 1 1 3.64898 1 6.91667C1 10.1844 3.64898 12.8333 6.91667 12.8333Z" stroke={`${data.gender === 'female' ? 'white' : 'black'}`} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6.91699 12.834V18.7507" stroke={`${data.gender === 'female' ? 'white' : 'black'}`} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M3.62988 16.1211H10.204" stroke={`${data.gender === 'female' ? 'white' : 'black'}`} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                <span>Female</span>
                            </div>
                            {data.gender === 'female' && (
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                            )}
                        </label>
                    </div>

                    <button className="w-full p-3 mt-10 text-sm text-white border md:w-10/12 bg-brandRed" onClick={handleLetsGo}>
                        Let's Go
                    </button>
                </div>

                <div className="absolute inset-x-0 bottom-0 lg:bottom-10">
                    <div className="w-full bg-gray-200">
                        <div className="py-1 text-xs leading-none text-center text-white bg-brandRed" style={{ width: "28%" }}></div>
                    </div>
                </div>
            </div>


        </div>

    );
}

export default Index;