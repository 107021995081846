import React, { useState, memo } from 'react';
import { LazyImage } from 'components/image';
import { closeBtnWhite } from 'assets/common/icons';

function Index({ data, isPreview = false }) {

    const [prevImgUrl, setPrevImgUrl] = useState(null)

    return (
        <div className='flex flex-col items-start justify-start w-full h-full mx-auto'>
            <div className={`h-fit ${isPreview ? "w-[330px]" : 'md:border-2 md:border-div_border w-[430px]'}`}>
                {!isPreview &&
                    <p className='font-medium text-[20px] text-gray-600 m-3'>Gallery</p>
                }
                <div className={`cursor-pointer grid grid-cols-3 ${isPreview ? '' : 'm-3'}`}>
                    {data?.images?.length > 0 &&
                        <>
                            {data?.images.map((image, index) => (
                                <div key={index} onClick={() => setPrevImgUrl(image.url)} className={`${isPreview ? 'mb-2' : 'mb-3'}`}>
                                    <LazyImage src={image.url} alt='gallery' className={`${isPreview ? 'w-[100px] h-[120px]' : 'w-[120px] h-[150px]'}`} />
                                </div>
                            ))}
                        </>
                    }
                </div>
            </div>

            {prevImgUrl !== null && (
                <div className='fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
                    <div className='relative'>

                        <button className="absolute z-50 -mt-8 text-sm text-white transform -translate-x-1/2 left-1/2"
                            onClick={() => setPrevImgUrl(null)}>
                            <img src={closeBtnWhite} alt='close' className='w-[24px] h-[24px]' />
                        </button>

                        <LazyImage src={prevImgUrl} alt='selectedimage' className='w-fit h-[550px]' />
                    </div>
                </div>

            )}
        </div>
    );
}

export default memo(Index);
