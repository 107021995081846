import React, { useState, useEffect, lazy, Suspense } from 'react';
import coinIcon from '../../../assets/icons/coin.png'
import { useNavigate, useParams } from 'react-router-dom';
import { bookTheEvent, getBookingDetails } from '../../../apis/events';
import { decodeFromBase64 } from '../../../utils/encodeDecode';
import { MAIN_NAV } from '../../../shared/router';
import { paymentStatus } from '../../../apis/wallet';

const AddAndEditAttendees = lazy(() => import("./editPopup"))
const PaymentSummary = lazy(() => import("components/payment").then(module => ({ default: module.PaymentSummary })))
const RazorPay = lazy(() => import("components/payment").then(module => ({ default: module.RazorPay })))
const EventBookingSuccess = lazy(() => import("components/popUps").then(module => ({ default: module.EventBookingSuccess })))
const FreeBookingSuccess = lazy(() => import("components/popUps").then(module => ({ default: module.PaymentSuccess })))
const NotEligibleForEvent = lazy(() => import("components/popUps").then(module => ({ default: module.NotEligibleForEvent })))

const PaymentComponent = () => {

    const { id } = useParams()
    const navigate = useNavigate()

    const [nonEligibleReason, setNonEligibleReason] = useState(true)
    const [isAttends, setIsAttends] = useState(true)
    const [bookingDetails, setBookingDetails] = useState()
    const [razoorpayPaymentStatus, setRazoorpayPaymentStatus] = useState(false)
    const [sendTicket, setSendTicket] = useState()
    const [attends, setAttends] = useState()
    const [apply_coins, setApply_coins] = useState(false)

    const [isVisible, setIsVisible] = useState({
        prevPage: true,
        editPopup: false,
        razoorPay: false,
        paymentSummary: false,
        bookingSuccess: false,
        freeBookingSuccess: false,
    })
    const [paymentStatusData, setPaymentStatusData] = useState()
    const [paymentStructure, setPaymentStructure] = useState()
    const [paymentData, setPaymentData] = useState({
        orderId: null,
        total: null,
        paymentId: null,
        status: null,
        gst: null,
        coupon: null,
        discounted_amount: 0
    })

    const fetchBookingDetails = async () => {
        const res = await getBookingDetails(decodeFromBase64(id))
        setAttends([{ email: res?.data?.email, name: res?.data?.name }])
        setSendTicket([{ email: res?.data?.email, name: res?.data?.name, phone: res?.data?.phone }])
        setBookingDetails(res?.data)
    }

    const handleSubmit = (value) => {
        if (isAttends) {
            setAttends(value)
        } else {
            setSendTicket(value)
            setIsAttends(true)
        }
        updateVisibility('prevPage')
    }

    const handleNextBtn = async () => {
        const attendeeData = {
            apply_coins: apply_coins,
            attendees: attends,
            share_ticket: sendTicket,
        }

        const res = await bookTheEvent(decodeFromBase64(id), attendeeData)
        updateVisibility('eligiblePopup')
        setNonEligibleReason(res?.response?.data?.message)

        if (res?.success) {
            setPaymentStatusData(res?.data)
            if (res?.isFree) {
                setPaymentStatusData(res)
                updateVisibility('freeBookingSuccess')
            } else {
                const data = { ...res.payment_structure, id: res?.data?.id }
                setPaymentData(prev => ({ ...prev, orderId: res?.data?.id }))
                setPaymentStructure(data)
                updateVisibility('paymentSummary')
            }
        }
    }

    const handlePay = (value) => {
        setPaymentData(prev => ({ ...prev, ...value }))
        updateVisibility('razoorPay')
    }

    const updateThePayment = async (data) => {
        const { total, ...other } = data
        const res = await paymentStatus(other)
        if (res?.success) {
            setPaymentStatusData(res?.data?.response)
            updateVisibility('bookingSuccess')
        }
    }

    const razorpaySuccess = async (value) => {
        if (value?.status === 'failed') {
            setRazoorpayPaymentStatus(true)
            updateVisibility('bookingSuccess')
        } else {

            const data = {
                ...paymentData,
                paymentId: value.paymentId,
                status: value.status
            }
            await updateThePayment(data)
        }
    }


    const updateVisibility = (key) => {
        setIsVisible({
            prevPage: false,
            editPopup: false,
            razoorPay: false,
            paymentSummary: false,
            bookingSuccess: false,
            freeBookingSuccess: false,
            eligiblePopup: false,
            [key]: true,
        });
    };

    const handleDoneBtn = () => {
        navigate(MAIN_NAV.HOME)
    }

    useEffect(() => {
        fetchBookingDetails()
    }, [])



    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">

            {isVisible.razoorPay && (
                <Suspense fallback={<div>Loading...</div>}>
                    <RazorPay amount={paymentData.total} orderId={paymentData.orderId} handleOnclick={(value) => razorpaySuccess(value)} />
                </Suspense>
            )}
            {isVisible.paymentSummary && (
                <Suspense fallback={<div>Loading...</div>}>
                    <PaymentSummary data={paymentStructure} handlePay={(value) => handlePay(value)} />
                </Suspense>
            )}
            {isVisible.eligiblePopup && (
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="w-[40%] h-fit">
                        <NotEligibleForEvent description={nonEligibleReason} handleClose={() => updateVisibility('prevPage')} />
                    </div>
                </Suspense>
            )}
            {isVisible.bookingSuccess && (
                <>
                    {razoorpayPaymentStatus ?
                        <Suspense fallback={<div>Loading...</div>}>
                            <FreeBookingSuccess
                                handleDoneBtn={() => navigate(MAIN_NAV.HOME)}
                                isFailed={razoorpayPaymentStatus}
                            />
                        </Suspense>
                        :
                        <Suspense fallback={<div>Loading...</div>}>
                            <EventBookingSuccess
                                amount={paymentData.total}
                                eventName={paymentStatusData?.eventId?.title}
                                paymentTime={paymentStatusData?.booked_date}
                                senderName={paymentStatusData?.userId?.name}
                                booking_id={paymentStatusData?.booking_id}
                                handleDoneBtn={handleDoneBtn} />
                        </Suspense>
                    }
                </>
            )}
            {isVisible.freeBookingSuccess && (
                <Suspense fallback={<div>Loading...</div>}>
                    <FreeBookingSuccess
                        mainText={'Event Booked Successfully'}
                        subText={`Event Id ${paymentStatusData?.booking_id}`}
                        handleDoneBtn={() => navigate(MAIN_NAV.HOME)}
                    />
                </Suspense>
            )}

            {isVisible.editPopup &&
                <Suspense fallback={<div>loading...</div>} children={
                    <AddAndEditAttendees
                        handlePopup={() => updateVisibility('prevPage')}
                        existingAttends={isAttends ? attends : sendTicket}
                        handleSubmit={(value) => handleSubmit(value)}
                        isAttends={isAttends}
                    />} />
            }
            {isVisible.prevPage &&
                <div className="w-full max-w-md p-6  rounded-lg shadow-lg">
                    <h2 className="mb-4 text-2xl font-bold text-center text-black">Payment</h2>

                    <div className="mb-4">
                        <div className="flex justify-between items-center bg-[#dce2f0] p-3 rounded-t-md">
                            <span className="text-gray-700">Attendees Details</span>
                            {bookingDetails?.event_relationship_status !== 'single' &&
                                <button className="text-blue-500" onClick={() => {
                                    updateVisibility('editPopup')
                                }}>Edit</button>
                            }
                        </div>
                        {attends?.map((item, index) => (
                            <div key={index} className="p-4 border border-t-0 rounded-b-md">
                                <p className="font-semibold text-gray-800">{item?.name}</p>
                                <p className="text-gray-600">{item?.email}</p>
                            </div>
                        ))}
                    </div>

                    {bookingDetails?.event_relationship_status !== 'single' &&
                        <div className="mb-4">
                            <div className="flex justify-between items-center bg-[#dce2f0] p-3 rounded-t-md">
                                <span className="text-gray-700 ">Sent Ticket to</span>
                                <button className="text-blue-500" onClick={() => {
                                    updateVisibility('editPopup')
                                    setIsAttends(false)
                                }}>Edit</button>
                            </div>
                            {sendTicket?.map((item, index) => (
                                <div key={index} className="p-4 border border-t-0 rounded-b-md">
                                    <div className="flex items-center justify-between">
                                        <p className="font-semibold text-gray-800">{item?.name}</p>
                                        <p className="text-gray-600">{item?.email}</p>
                                    </div>
                                    <p className="text-gray-600">{item?.phone}</p>
                                </div>
                            ))}
                        </div>
                    }
                    {!bookingDetails?.isFree &&
                        <div className="mb-4">
                            <div className="flex items-center bg-[#dce2f0] p-4 rounded-md">
                                <input type="checkbox" value={apply_coins} onClick={() => setApply_coins(!apply_coins)} className="w-5 h-5 text-blue-600 cursor-pointer form-checkbox" />
                                <div className="text-start ps-3">
                                    <span className="text-black">Pay Using Coins</span>
                                    <p className="text-green-500 text-[11px]">Available Coins: {bookingDetails?.available_balance}</p>
                                    <p className="text-gray-400  text-[11px]">Max Redeemable coins {bookingDetails?.max_redeemable_coins}</p>
                                </div>
                                <div className="flex items-center ml-auto">
                                    <div className="p-2 bg-yellow-300 rounded-full">
                                        <img src={coinIcon} alt="coin icon" className="w-15 h-15" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                    <button className="w-full py-3 font-semibold text-white transition duration-200 bg-red-500 rounded-md hover:bg-red-600" onClick={handleNextBtn}>
                        Next
                    </button>
                </div>
            }
        </div>
    );
};

export default PaymentComponent;
