import React, { useState, useEffect, Suspense } from 'react'
import 'react-toastify/dist/ReactToastify.css';

import { useParams } from 'react-router-dom';

import { fetchMutualConnections, getFriendProfilePreview } from 'apis/matches';
import { decodeFromBase64 } from 'utils/encodeDecode';
import { ConnectionActions, UserFullDetails, UserFullDetailsResponsive, UserImgPreview } from 'components/discover';
import { MutualConnection } from 'components/popUps';

// Assets

function Index() {
    const { id } = useParams();
    const [userData, setUserData] = useState()
    const [mutualConnections, setMutualConnections] = useState([])
    const [mutualConnectionPopups, setMutualConnectionsPopups] = useState({
        count: false,
        users: false
    })

    const fetchFriendsProfilePreview = async () => {
        const res = await getFriendProfilePreview(decodeFromBase64(id))
        if (res?.data) {
            setUserData(res?.data)
        }
    }

    useEffect(() => {
        fetchFriendsProfilePreview()
    }, [])


    const handleMutualConnection = async () => {
        const res = await fetchMutualConnections(decodeFromBase64(id))
        setMutualConnections(res?.data)
        setMutualConnectionsPopups({ users: true, count: res?.data?.length })
    }

    return (
        <div className="flex flex-col h-full min-h-screen bg-[#efefef]">

            {mutualConnectionPopups.users && (
                <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full bg-black bg-opacity-75">
                    <Suspense fallback={<div>Loading...</div>}>
                        <MutualConnection users={mutualConnections} closeBtn={() => setMutualConnectionsPopups(prev => ({ ...prev, users: false }))} />
                    </Suspense>
                </div>
            )}

            <div className='flex items-center justify-center flex-grow w-full '>
                <div className='flex flex-wrap items-start justify-center w-full gap-0 md:gap-x-10 '>
                    {/* ImgPreview Component */}
                    <div className="flex flex-col items-center">
                        <div className="h-[510px] w-full md:w-[360px] mx-auto flex items-center justify-center">
                            <UserImgPreview page={"friendsProfilePrev"} profilePreview={true} data={userData} />
                        </div>

                        <div className="flex items-center justify-center mt-4 gap-9">
                            <ConnectionActions
                                handleMutualConnection={handleMutualConnection}
                            />
                        </div>
                    </div>

                    <div className="hidden md:block h-[580px] w-[60%] overflow-y-auto">
                        <UserFullDetails data={userData} />
                    </div>
                    <div className="block mt-4 overflow-y-auto md:hidden">
                        <UserFullDetailsResponsive data={userData} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Index