import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { googlePlayLabelWhite, appStoreLabelWhite } from 'assets/common/cloudinary';

const bannerImg = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/siteIntro/landing/txpxpxuqfeviexcd6r2t`
const bannerBg = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/siteIntro/landing/lhiubxn91rsdfyyhomal`
const bannerBgResponsive = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/siteIntro/landing/ngdomhzjydc9pmjmoxrh`

function Index() {

    const [bgImage, setBgImage] = useState(
        window.innerWidth >= 768 ? bannerBg : bannerBgResponsive
    );

    useEffect(() => {
        const handleResize = () => {
            setBgImage(window.innerWidth >= 768 ? bannerBg : bannerBgResponsive);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (

        <div className="relative flex flex-col items-center h-full min-h-screen text-white bg-center bg-cover md:justify-start"
            style={{
                backgroundImage: `url(${bgImage})`,
            }}

        >
            {/* Content Section */}
            <div className="flex flex-col items-center justify-center w-full px-4 mt-40 md:mt-[4%] text-center lg:px-0">
                <h1 className="text-[25px] lg:text-[40px] mt-3">
                    India's First Event-Based Social <br /> Community for Singles & Couples
                </h1>
                <p className="text-sm md:text-base mb-5 mt-2 max-w-[95%] md:max-w-[63%] mx-auto">
                    Join Couple Squad Community for Singles and Couples, and find a better
                    version of yourself.
                </p>

                <div className="flex items-center justify-start my-8 space-x-4">
                    <Link to={'https://apps.apple.com/us/app/couple-squad/id6624305594'}>
                        <img src={appStoreLabelWhite} alt="CoupleSquad" className="h-12" />
                    </Link>
                    <Link to={"https://play.google.com/store/apps/details?id=com.couplesquad.app"}>
                        <img src={googlePlayLabelWhite} alt="CoupleSquad" className="h-12" />
                    </Link>
                </div>

                {/* Banner Image */}
                <img src={bannerImg} alt='CoupleSquad'
                    className="absolute bottom-0 w-full md:w-[40%]"
                />
            </div>
        </div>
    );
}

export default React.memo(Index)


// maxHeight: 'calc(100vh - 65px)',