import React from 'react'
const noMatches = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/Popups/noMatches_o0abqu`

function Index() {
    return (
        <div className='w-full md:w-[436px] h-[498px] bg-white flex flex-col justify-center items-center m-4 '>
            <div>
                <img src={noMatches} alt='limits' className='w-full h-full mb-2 cursor-pointer' />
            </div>
        </div>
    )
}

export default React.memo(Index)