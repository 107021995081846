import React, { useEffect } from "react";
import ReactPixel from 'react-facebook-pixel';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import './fonts.css'
import Routers from "./common/Routers";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { useSelector } from "react-redux";
import GuidePopUp from "components/popUps/guid";
import { PaymentCancellationReason } from "components/popUps";


function App() {
  const { isGuide } = useSelector((state) => state.guide);
  const isCancellationPopupVisible = useSelector(state => state.paymentCancellationPopup.isVisible);

  useEffect(() => {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID); // Replace with your Pixel ID
    ReactPixel.pageView(); // Track initial page view
  }, [])


  return (
    <div className="bg-white font-inter">
      <BrowserRouter>
        <Routers />
        {isCancellationPopupVisible && <PaymentCancellationReason />}
        {isGuide && <GuidePopUp />}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SpeedInsights />
      </BrowserRouter>
    </div>
  );
}

export default App;