import React, { useEffect, useState } from 'react'

// Components
import TableOfContent from './components/tableOfContent'
import ArticleContent from './components/articleContent'

import { useParams } from 'react-router-dom';
import { fetchBlogDetailBySlug } from 'apis/siteIntro/blog';
import { getTheApp } from 'utils/getTheApp';
import emojiRegex from 'emoji-regex';



function Index() {
    const { slug } = useParams();
    const [blogData, setBlogData] = useState()
    const [toc, setToc] = useState([])

    const extractHeadings = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        // Select both h2 and h3 tags
        const headings = [
            ...Array.from(doc.querySelectorAll('h2')),
            ...Array.from(doc.querySelectorAll('h3')),
        ]
            .map((heading, index) => {
                // Use emoji-regex to clean the text
                const regex = emojiRegex();
                const cleanedText = heading.textContent.replace(regex, '').trim();

                return {
                    id: `heading-${index}`,
                    text: cleanedText,
                };
            });
        return headings;
    };


    const getBlogDetailBySlug = async () => {
        const res = await fetchBlogDetailBySlug(slug)
        if (res?.success) {
            setBlogData(res?.data)
            const toc = extractHeadings(res?.data?.blog_content);
            setToc(toc);
        }
    }


    const handleOnClickImage = (value) => {
        if (value === 'app_link') {
            getTheApp()
        } else {
            window.open(value, "_blank");
        }
    }


    useEffect(() => {
        getBlogDetailBySlug()
    }, [slug])


    return (

        <div className="min-h-screen bg-white">
            <div className="flex flex-col lg:flex-row pt-[20%] md:pt-[7%] md:p-8 w-[95%] mx-auto">
                {/* Left Sidebar */}
                <div className="w-full lg:w-1/4">
                    <div className="sticky top-[17%]">
                        <TableOfContent toc={toc} />
                    </div>
                </div>

                {/* Main Content */}
                <div className="w-full lg:w-2/4">
                    <ArticleContent blogData={blogData} />
                </div>

                {/* Right Sidebar */}
                <div className="w-full lg:w-1/4">
                    <div className="w-full p-4 text-black sticky top-[17%]">
                        <img
                            src={blogData?.advertisement_right_side?.image_url}
                            alt={blogData?.advertisement_right_side?.alt_text}
                            onClick={() => handleOnClickImage(blogData?.advertisement_right_side?.link)}
                            className="object-cover w-auto h-auto cursor-pointer"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Index;