import { useDispatch } from "react-redux";

export const isHTML = (str) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};

export const guidPosition = (ref, dispatch, setPosition, additionalPosition = {}) => {
    if (!ref.current) {
        console.warn("Reference is not attached to an element");
        return;
    }

    const rect = ref.current.getBoundingClientRect();
    const popupWidth = 300; // Approximate width of the popup (adjust as needed)

    const position = {
        top: rect.bottom + window.scrollY + (additionalPosition.top || 0), // Apply positive/negative vertical offset
        left: rect.right + window.scrollX - popupWidth + (additionalPosition.left || 0), // Apply positive/negative horizontal offset
    };


    // Dispatch position to Redux store
    dispatch(setPosition(position));
};