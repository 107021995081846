import { useState, lazy, Suspense, useEffect } from 'react'
import { getHandpickedMatches } from '../../../apis/suggested'
import Guid from "./components/guid"
import ConnectionTab from 'components/NavbarAndTabs/ConnectionTabNav'

const SuggestedBasedConnections = lazy(() => import("components/discover").then(module => ({ default: module.SuggestedBasedConnections })))

function Index() {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [showMatches, setShowMatches] = useState(false)
    const [matchesData, setMatchesData] = useState([])

    const guidContent = [
        {
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Connections/Handpick_Matches/or366bh3qkvevdcnfe4i`,
            title: 'Premium Feature',
            description: 'Handpicked Matches'
        },
        {
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Connections/Handpick_Matches/mktol5cke68rnfsie5l6`,
            title: 'What is it',
            description: 'Receive personalized matches curated just for you by our expert team, based on your unique preferences.'
        },
        {
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Connections/Handpick_Matches/i8ryn8i8mcr2e0y8fxwg`,
            title: 'How does it help you?',
            description: 'No more searching endlessly! We provide you with quality matches that align with your interests and relationship goals.'
        },
        {
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Connections/Handpick_Matches/pkmjenmlas1jwih02gmm`,
            title: 'Why Choose Handpicked Matches?',
            description: 'Our matchmaking experts carefully select each match based on your preferences. This saves you time and ensures that every match meets your criteria.'
        },
        {
            img: `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Connections/Handpick_Matches/gb0pqqr1zesmkbnokfzl`,
            title: false,
            description: false
        },
    ]


    const handleClickCard = async () => {
        const res = await getHandpickedMatches()
        if (res?.success) setMatchesData(res?.data)
    }

    useEffect(() => {
        handleClickCard()
    }, [])


    const handleNext = () => {
        if (currentIndex !== 3) {
            setCurrentIndex((prev) => prev + 1)
        } else if (currentIndex === 3) {
            if (matchesData?.length !== 0) {
                setShowMatches(true)
            } else {
                setCurrentIndex((prev) => prev + 1)
            }
        }
        else {
            setShowMatches(true)
        }
    }

    const handleSkip = () => {
        if (matchesData?.length !== 0) {
            setShowMatches(true)
        } else {
            setCurrentIndex(4)
        }
    }

    return (
        <div className="flex flex-col h-full min-h-screen bg-[#f9f9f9]">


            {showMatches ?
                <Suspense fallback={<div>loading...</div>}>
                    <SuggestedBasedConnections matches={matchesData} handleCloseBtn={() => setShowMatches(false)} currentOption={'handPicked'} />
                </Suspense>
                :
                <div className='flex flex-col items-center justify-center'>
                    {/* Tabs */}
                    <div className="flex flex-col items-center w-full bg-[#F9F9F9] mt-5">
                        <div className='w-10/12 h-full mt-3'>
                            <ConnectionTab />
                        </div>
                    </div>

                    <div className="w-[90%] h-full mb-20">
                        <Guid
                            index={currentIndex}
                            image={guidContent?.[currentIndex]?.img}
                            title={guidContent?.[currentIndex]?.title}
                            description={guidContent?.[currentIndex]?.description}
                            onNext={handleNext}
                            onSkip={handleSkip}
                        />
                    </div>

                </div>
            }

        </div>

    )
}

export default Index