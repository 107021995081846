
import back_btn_icon from "../../../assets/onBoarding/back-btn-icon.svg"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImageCropping } from "components/image";
import { addImage, deleteImage, getImages } from "../../../apis/onBoarding";
import { toast } from "react-toastify";

const bg_img = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Auth/Onboarding/mj4hsupunl1fv24k350h`

function Index() {

    const navigate = useNavigate()
    const [images, setImages] = useState(
        Array.from({ length: 6 }, (_, index) => ({ imageId: `image-${index}`, url: '' }))
    );

    const [isCropping, setIsCropping] = useState(false);
    const [firstImg, setFirstImg] = useState(false);
    const [currentImageForCropping, setCurrentImageForCropping] = useState(null);

    useEffect(() => {
        const serializedObject = localStorage.getItem('userData');
        const userData = JSON.parse(serializedObject);

        if (userData?.onBoardingSix) {
            navigate('/on-boarding-7')
        }
        handleStateUpdate()

    }, [])

    const handleFileChange = (event, id) => {
        if (event.target.files && event.target.files[0]) {
            const fileReader = new FileReader();
            fileReader.onload = function (e) {
                setCurrentImageForCropping(e.target.result);
                setIsCropping(true);
            };
            fileReader.readAsDataURL(event.target.files[0]);
        }
    };

    const onCropComplete = async (croppedFile) => {
        const toastId = toast.loading("Uploading Image Please wait")
        if (firstImg) {
            await addImage({ index: 1, aspect_ratio: 16 / 9, file: croppedFile })
        } else {
            const index = images.findIndex(img => img.url === '');
            await addImage({ index: index + 1, aspect_ratio: 16 / 9, file: croppedFile })
        }
        await handleStateUpdate()
        setIsCropping(false);
        toast.dismiss(toastId)
    };

    const handleStateUpdate = async () => {
        try {
            const res = await getImages();
            const defaultImages = Array.from({ length: 6 }, (_, index) => ({
                imageId: `image-${index}`,
                url: ''
            }));
            const updatedImages = defaultImages.map((img, Index) => {
                if (Index < res.data.length) {
                    return {
                        imageId: `image-${res.data[Index].index}/${res.data[Index].imageId}`,
                        url: res.data[Index].url
                    };
                }
                return img;
            });
            setImages(updatedImages);

        } catch (error) {
            toast.error('Something went wrong')
        }
    };



    const handleRemoveImage = async (id) => {
        const toastId = toast.loading("Deleting Image please wait")
        const extractedIndex = id.split('/')[0].split('-')[1];
        const index = parseInt(extractedIndex, 10);
        await deleteImage({ index })
        await handleStateUpdate()
        toast.dismiss(toastId)
    };

    const handleBackBtn = () => {
        const serializedObject = localStorage.getItem('userData');
        const currentUserData = JSON.parse(serializedObject) || {};
        const updatedUserData = {
            ...currentUserData,
            onBoardingFive: false,
        };
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        navigate('/on-boarding-5')
    }

    const handleLetsGo = () => {
        const index = images.findIndex(img => img.url === '');
        if (index < 1) {
            toast.error("upload at least 1 Images")
        } else {
            navigate('/on-boarding-7')
            const serializedObject = localStorage.getItem('userData');
            const currentUserData = JSON.parse(serializedObject) || {};
            const updatedUserData = {
                ...currentUserData,
                onBoardingSix: true,
            };
            localStorage.setItem('userData', JSON.stringify(updatedUserData));
        }
    }

    return (
        <>
            {isCropping && (
                <div className='absolute top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full overflow-hidden '>
                    <div className="w-full max-w-lg p-4 bg-white rounded-lg">
                        <ImageCropping
                            image={currentImageForCropping}
                            setPopup={setIsCropping}
                            onCropComplete={onCropComplete}
                            circularCrop={false}
                            ratio={4 / 5}
                        />
                    </div>
                </div>
            )}

            <div className="flex flex-col min-h-screen bg-white lg:flex-row">
                <div className="relative flex-1 hidden overflow-hidden bg-center bg-cover lg:block lg:h-auto h-1/2 lg:w-full">
                    <img src={bg_img} alt="" className="object-cover w-full h-screen" />

                    <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-50">
                        <h1 className="p-4 font-semibold text-7xl">Find all your needs <br />  in one place</h1>
                        <p className="p-4">Whether you view us as a social community, event listing, <br /> matchmaking, or relationship coaching platform, we're here to <br /> assist you in every way.</p>
                    </div>
                </div>


                <div className="relative flex flex-col items-center justify-center flex-1 w-full h-screen mx-auto my-10 bg-white lg:h-auto lg:w-6/12 lg:my-0 text-brandRed ">

                    <img src={back_btn_icon} alt="back" onClick={handleBackBtn} className="absolute top-0 left-0 p-2 mt-5 ml-5 text-sm text-white cursor-pointer" />


                    <div className="flex flex-col items-center w-full mx-auto mb-5 md:w-7/12 lg:mb-0">
                        <h1 className="text-2xl font-semibold text-black">Add your best pics!</h1>
                        <p className="mt-5 text-sm text-gray-500 mb-7">Upload 2 photos to start, 1st must be a <br /> picture of you</p>

                        <div className="grid grid-cols-2 gap-4 mx-auto mt-4 md:grid-cols-3">
                            {images.map((imgPreview, index) => (
                                <div key={imgPreview.imageId} className="relative flex flex-col items-center justify-center border border-gray-700" style={{ width: '112px', height: '112px' }}>
                                    {imgPreview.url ? (
                                        <>
                                            <img src={imgPreview.url} alt={`preview ${index + 1}`} className="max-w-[112px] max-h-[112px] outline-none border-none" />
                                            {index + 1 === 1 && (
                                                <button onClick={() => document.getElementById(`input-${imgPreview.imageId}`).click()} className="absolute bottom-[-12px] right-[-12px] w-6 h-6 bg-white rounded-full" style={{ width: '25px', height: '25px' }}>
                                                    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.619141" y="0.601562" width="33.7854" height="33.7854" rx="16.8927" fill="#F83758" />
                                                        <path d="M9.5 9.5L25.5 25.5M9.5 25.5L25.5 9.5" stroke="white" strokeWidth="2.25236" strokeLinecap="round" />
                                                    </svg>
                                                </button>
                                            )}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    handleFileChange(e, imgPreview.imageId)
                                                    setFirstImg(true)
                                                }}
                                                id={`input-${imgPreview.imageId}`}
                                                className="hidden"
                                            />

                                            {index + 1 !== 1 && (
                                                <button onClick={() => handleRemoveImage(imgPreview.imageId)} className="absolute bottom-[-12px] right-[-12px] w-6 h-6 bg-white rounded-full" style={{ width: '25px', height: '25px' }}>
                                                    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.619141" y="0.601562" width="33.7854" height="33.7854" rx="16.8927" fill="#F83758" />
                                                        <path d="M9.5 9.5L25.5 25.5M9.5 25.5L25.5 9.5" stroke="white" strokeWidth="2.25236" strokeLinecap="round" />
                                                    </svg>
                                                </button>

                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleFileChange(e, imgPreview.imageId)}
                                                id={imgPreview.imageId}
                                                className="hidden mb-2"
                                            />
                                            <label htmlFor={imgPreview.imageId} className="text-sm text-gray-700 cursor-pointer">Choose an image</label>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>

                        <button className="p-3 border bg-brandRed text-white text-sm w-[93%] mt-10" onClick={handleLetsGo}>
                            Let's Go
                        </button>
                    </div>



                    <div className="absolute inset-x-0 bottom-0 lg:bottom-5">
                        <div className="w-full bg-gray-200">
                            <div className="py-1 text-xs leading-none text-center text-white bg-brandRed" style={{ width: "85%" }}></div>
                        </div>
                    </div>
                </div>
            </div >
        </>

    );
}

export default Index;

