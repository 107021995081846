import { getInvitationLink } from 'apis/matches';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CONNECTIONS } from 'shared/router';
import { encodeToBase64 } from 'utils/encodeDecode';


function Index({ closeBtn, users }) {
    const userInfo = useSelector((state) => state.userProfile);

    const navigate = useNavigate();

    const navigateToConnectionPreview = (id, phone) => {
        const basePath = CONNECTIONS.CONNECTION_FRIEND_PREVIEW.replace(':id', encodeToBase64(id));
        const url = `${basePath}?phone=${encodeURIComponent(phone)}`;
        navigate(url);
    };


    const handleInvite = async () => {
        const id = userInfo?.id
        const res = await getInvitationLink(id)
        if (res.success) {
            toast.success('Link copied to clipboard')
            await navigator.clipboard.writeText(res.data.link);
        } else {
            toast.error('Failed to get link. Please try again later.');
        }
    }


    return (
        <div className="mx-auto bg-white rounded-lg shadow-md w-[550px]">
            <div className="px-4 py-5 text-lg font-semibold text-white rounded-t-lg bg-brandRed">
                {users?.length === 0 ? "No Mutual Friends" : "Mutual Friends"}
            </div>
            <div className="overflow-y-auto max-h-96">
                {users?.map((item, index) => (
                    <div key={index} className="flex items-center justify-between px-4 py-3 border-b">
                        <div className="flex items-center gap-4">
                            <img src={item?.photo} alt={item?.name} className="w-10 h-10 rounded-full" />
                            <div>
                                <h3 className="font-semibold text-gray-800">{item?.first_name + item?.last_name}</h3>
                                <p className="text-gray-600">{item?.phone}</p>
                            </div>
                        </div>
                        <button onClick={() => {
                            if (item?.account_exists) {
                                navigateToConnectionPreview(item?.userId, item?.phone)
                            } else {
                                handleInvite()
                            }
                        }}
                            className={`px-4 py-2 rounded-md font-semibold ${item?.account_exists
                                ? 'bg-brandRed text-white'
                                : 'bg-black text-white'
                                }`}
                        >
                            {item?.account_exists ? 'View' : 'Invite'}
                        </button>
                    </div>
                ))}
            </div>
            <button onClick={closeBtn} className="w-full py-3 font-semibold text-white rounded-b-lg bg-brandRed">
                Cancel
            </button>
        </div>
    );
};

export default Index;
