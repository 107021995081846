import { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CONNECTIONS } from "shared/router";
import { encodeToBase64 } from "utils/encodeDecode";

function Index() {
    const navigate = useNavigate()
    const scanner = useRef();
    const videoEl = useRef(null);
    const qrBoxEl = useRef(null);
    const [apiCalled, setApiCalled] = useState(false);
    const [qrOn, setQrOn] = useState(true);


    const onScanSuccess = (result) => {
        if (!apiCalled) {
            sendToAPI(result.data);
            setApiCalled(true);
        }
    };

    // Fail
    const onScanFail = (err) => {
        toast.error(err?.message)
    };

    const sendToAPI = async (data) => {
        navigate(CONNECTIONS.CONNECTION_FRIEND_PREVIEW.replace(':id', encodeToBase64(data)));
    };

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            // Instantiate the QR Scanner
            scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
                onDecodeError: onScanFail,
                preferredCamera: "environment",
                highlightScanRegion: true,
                highlightCodeOutline: true,
                overlay: qrBoxEl?.current || undefined,
            });

            // Start QR Scanner
            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        // Clean up on unmount
        return () => {
            if (!videoEl?.current) {
                scanner?.current?.stop();
            }
        };
    }, []);

    // Alert if camera access is not allowed
    useEffect(() => {
        if (!qrOn) {
            alert(
                "Camera is blocked or not accessible. Please allow camera in your browser permissions and reload."
            );
        }
    }, [qrOn]);

    return (
        <div className="qr-reader">
            {/* QR Scanner */}
            <video ref={videoEl}></video>
            <div ref={qrBoxEl} className="qr-box"></div>

        </div>
    );
};

export default Index;
