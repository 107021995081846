import { auth } from "..";


export const createSlots = async (data) => {
    const res = await auth({
        method: 'POST',
        url: `/meetings/slots`,
        data,
    });
    return res;
}

export const getAvailableSlotsOfEndUser = async (id) => {
    const res = await auth({
        method: 'GET',
        url: `/meetings/slots/${id}`,
    });
    return res;
}

export const requestForTheMeeting = async (data) => {
    const res = await auth({
        method: 'POST',
        url: `/meetings/request`,
        data
    });
    return res;
}

export const getCurrentUserSlots = async () => {
    const res = await auth({
        method: 'GET',
        url: `/meetings/slots`,
    });
    return res;
}

export const deleteSlot = async (data) => {
    const res = await auth({
        method: 'DELETE',
        url: `/meetings/slot`,
        data
    });
    return res;
}

export const updateDefaultSlotCreationStatus = async (status) => {
    const res = await auth({
        method: 'PUT',
        url: `/meetings/slots/default/${status}`,
    });
    return res;
}
export const createDefaultSlot = async () => {
    const res = await auth({
        method: 'POST',
        url: `/meetings/slots/default`
    });
    return res;
}

export const getOfflineMeetingLocations = async (data) => {
    const params = new URLSearchParams();
    for (const key in data) {
        if (data[key] !== null && data[key] !== undefined) {
            params.append(key, data[key].toString());
        }
    }
    const url = `/meetings/places?${params.toString()}`;
    const res = await auth({
        method: 'GET',
        url: url,
    });
    return res;
}

export const getOfflineMeetingLocationDetails = async (id) => {
    const res = await auth({
        method: 'GET',
        url: `/meetings/place/details/${id}`,
    });
    return res;
}

export const fetchMeetingsBasedOnStatus = async (status) => {
    const res = await auth({
        method: 'GET',
        url: `/meetings/request/${status}`,
    });
    return res;
}

export const updateMeetingRequestStatus = async (status, id) => {
    const res = await auth({
        method: 'PUT',
        url: `/meetings/request/${id}?status=${status}`,
    });
    return res;
}

export const getFriendsAvailableForMeeting = async (page, limit) => {
    const res = await auth({
        method: 'GET',
        url: `/meetings/friends?page=${page}&limit=${limit}`,
    });
    return res;
}