import React, { useEffect, useState } from "react";
import plusBlack from "assets/meetings/icons/plus_black.svg";
import deleteIcon from "assets/meetings/icons/delete.svg";
import downArrowIcon from "assets/meetings/icons/downArrow.svg";
import TimeSelector from "./timeSelector";
import { createSlots, deleteSlot } from "apis/meeting";
import { toast } from "react-toastify";
import { formatToDateYYYYMMDD } from "shared/dates";

// Used in the main page 

const Index = ({ handleSubmitted, availableSlots }) => {

    const [isTimeSelection, setIsTimeSelection] = useState(null);
    const [isDateSelection, setIsDateSelection] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [dates, setDates] = useState([]);
    const [dateTimeData, setDateTimeData] = useState([]);

    useEffect(() => {
        const transformedData = availableSlots
            .map(slot => ({
                date: formatToDateYYYYMMDD(slot.date),
                times: slot.times
                    .filter(timeSlot => timeSlot.isBooked === "free")
                    .map(timeSlot => ({
                        start: timeSlot.time.split(' - ')[0],
                        end: timeSlot.time.split(' - ')[1],
                    }))
            }))
            .filter(slot => slot.times.length > 0);

        setDateTimeData(transformedData);
        setDates(
            availableSlots
                .filter(slot => slot.times.some(timeSlot => timeSlot.isBooked === "free"))
                .map(slot => formatToDateYYYYMMDD(slot.date))
        );
    }, [availableSlots]);



    const handleTimeSubmit = (date, timeSlots) => {
        const updatedData = [...dateTimeData];
        const existingEntryIndex = updatedData.findIndex((item) => item.date === date);

        if (existingEntryIndex >= 0) {
            updatedData[existingEntryIndex].times = timeSlots;
        } else {
            updatedData.push({ date, times: timeSlots });
        }

        setDateTimeData(updatedData);
        setIsTimeSelection(null);
    };

    const handleAddDate = () => {
        if (selectedDate && !dates.includes(selectedDate)) {
            setDates((prev) => [...prev, selectedDate]);
            setIsDateSelection(false);
        }
    };

    const handleDeleteDate = async (date) => {
        const formattedDate = new Date(date).toISOString().split('T')[0];

        const slotExists = availableSlots.some(slot => {
            const availableDate = new Date(slot.date).toISOString().split('T')[0];
            return availableDate === formattedDate;
        });

        if (slotExists) {
            const res = await deleteSlot({ date });
            if (res?.success) {
                toast.success('Removed the slot');
                handleSubmitted()
            } else {
                toast.error('Failed to remove the slot');
                return;
            }
            setDates(dates.filter((item) => item !== formattedDate));
            setDateTimeData(dateTimeData.filter((item) => item.date !== formattedDate));
        } else {
            setDates(dates.filter((item) => item !== formattedDate));
            setDateTimeData(dateTimeData.filter((item) => item.date !== formattedDate));
        }
    };


    const handleSubmit = async () => {
        const toastId = toast.loading('creating slot')
        const payload = { date_time: dateTimeData };
        const res = await createSlots(payload)
        if (res?.success) {
            toast.success('Slot created')
            setDateTimeData([])
            setDates([])
        }
        handleSubmitted()
        toast.dismiss(toastId)
    };

    return (
        <div className="flex mt-20 flex-col items-center p-4 justify-center h-full border-2 border-gray-200 bg-white w-[40%] text-black">
            <div className="flex items-center justify-between w-full px-4">
                <div className="text-xl">My Slots</div>
                <img
                    onClick={() => setIsDateSelection(true)}
                    src={plusBlack}
                    alt="plusBlack"
                    className="w-8 h-8 cursor-pointer"
                />
            </div>

            {/* Date Selection Popup */}
            {isDateSelection && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] max-w-sm">
                        <h3 className="mb-4 text-lg font-bold">Select a Date</h3>
                        <input
                            type="date"
                            className="w-full p-2 mb-4 bg-white border rounded"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                        />
                        <div className="flex justify-end space-x-4">
                            <button
                                className="px-4 py-2 text-sm font-semibold text-white bg-red-500 rounded hover:bg-red-600"
                                onClick={() => setIsDateSelection(false)}
                            >
                                Close
                            </button>
                            <button
                                className="px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded hover:bg-blue-600"
                                onClick={handleAddDate}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="w-full overflow-y-auto max-h-[calc(100vh-350px)] mt-4">
                {dates.map((date, index) => {
                    const dateEntry = dateTimeData.find((item) => item.date === date);
                    const timeSlots = dateEntry ? dateEntry.times : [];

                    return (
                        <div key={index} className="w-full">
                            <div className="flex items-center w-full p-4 my-2 border-2 border-gray-200 rounded-lg shadow-sm">
                                <div className="flex items-center justify-center w-12 h-12 text-lg font-bold text-white bg-red-500 rounded-md">
                                    {date.slice(-2)}
                                </div>
                                <div className="flex-1 ml-4">
                                    <p className="text-sm font-medium text-gray-600">{date}</p>
                                </div>
                                <div className="flex space-x-4">
                                    <img
                                        src={deleteIcon}
                                        alt="deleteIcon"
                                        className="w-8 h-8 cursor-pointer"
                                        onClick={() => handleDeleteDate(date)}
                                    />
                                    <img
                                        src={downArrowIcon}
                                        alt="downArrowIcon"
                                        onClick={() => setIsTimeSelection(isTimeSelection === date ? null : date)}
                                        className={`w-8 h-8 cursor-pointer ${isTimeSelection === date && 'rotate-180'}`}
                                    />
                                </div>
                            </div>

                            {isTimeSelection === date && (
                                <TimeSelector date={date} onSubmit={handleTimeSubmit} time={timeSlots} />
                            )}
                        </div>
                    );
                })}
            </div>
            <button
                className="px-4 py-2 mt-6 text-white bg-green-500 rounded hover:bg-green-600"
                onClick={handleSubmit}
            >
                Submit
            </button>
        </div>
    );
};

export default Index;
