import React, { lazy, Suspense, useEffect, useState } from 'react'

// Components
import Banner from './components/banner'
import { fetchAllBlogs } from 'apis/siteIntro/blog'
const BlogCards = lazy(() => import("./components/blogCard"))

function Index() {

    const [blogs, setBlogs] = useState([])
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getAllBlogs = async (page) => {
        const res = await fetchAllBlogs(page)
        if (res?.success) {
            setBlogs(res?.data)
            const pagination = res?.pagination
            setPageNation({ currentPage: pagination?.current_page, nextPage: pagination?.next_page, prevPage: pagination?.previous_page })
        }
    }



    useEffect(() => {
        getAllBlogs(1)
    }, [])

    return (
        <>
            <Banner />

            <div className='h-full min-h-screen'>
                <div className="grid w-full grid-cols-1 gap-4 p-4 mx-auto md:grid-cols-2 lg:grid-cols-3 lg:w-[80%] bg-white">
                    {blogs?.map((blog, index) => (
                        <Suspense key={index} fallback={<div>Loading...</div>}>
                            <BlogCards blog={blog} />
                        </Suspense>
                    ))}
                </div>

                {pageNation.nextPage !== null &&
                    <div className="flex items-center justify-center space-x-2">
                        {/* First and Previous Buttons */}
                        {pageNation.prevPage !== null &&
                            <>
                                <button
                                    onClick={() => getAllBlogs(pageNation.prevPage)}
                                    className="px-3 py-1 border rounded-md hover:bg-gray-200"
                                // disabled={page === 1}
                                >
                                    &lsaquo;
                                </button>

                                <div className="p-2">{pageNation.prevPage}</div>
                            </>
                        }

                        <div className="p-2">{pageNation.currentPage}</div>

                        {pageNation.nextPage !== null &&
                            <>
                                <div className="p-2">{pageNation.nextPage}</div>

                                {/* Next and Last Buttons */}
                                <button
                                    onClick={() => getAllBlogs(pageNation.nextPage)}
                                    className="px-3 py-1 border rounded-md hover:bg-gray-200"
                                // disabled={page === totalPages}
                                >
                                    &rsaquo;
                                </button>
                            </>
                        }
                    </div>
                }

            </div>

        </ >
    )
}

export default Index