import React, { useEffect, useState } from "react";
import DateAndTimeCard from "./components/dateAndTime";
import { createDefaultSlot, getCurrentUserSlots, updateDefaultSlotCreationStatus } from "apis/meeting";
import { Calender, ToggleWithText } from "components/UI";
import MeetingDashboard from "./components/dashboard"
import { toast } from "react-toastify";


const Index = () => {
    const [availableSlots, setAvailableSlots] = useState([]);
    const [events, setEvents] = useState([]);
    const [pageIndex, setPageIndex] = useState(1)
    const [currentTab, setCurrentTab] = useState('Custom Slot')
    const [filteredSlot, setFilteredSlot] = useState([])

    const fetchCurrentUserSlots = async () => {
        try {
            const res = await getCurrentUserSlots();
            if (res?.success) {
                setAvailableSlots({
                    defaultSlot: res?.data?.defaultSlot,
                    slots: [...res?.data?.slots?.customSlots] || []
                });
            }
        } catch (error) {
            console.error("Error fetching user slots:", error);
        }
    };

    // Map available slots to events
    const mapAvailableDates = (slots) => {
        return slots.reduce((acc, current) => {
            current.times.forEach((time) => {
                const formattedDate = new Date(current.date).toISOString().split("T")[0];
                if (!acc[formattedDate]) acc[formattedDate] = [];

                if (time.isBooked === "free") {
                    acc[formattedDate].push({
                        title: "Selected",
                        start: formattedDate,
                        className: "event-available-2",
                    });
                }
                // else if (time.isBooked === "requested") {
                //     acc[formattedDate].push({
                //         title: "Requested",
                //         start: formattedDate,
                //         className: "event-requested",
                //     });
                // } else if (time.isBooked === "booked") {
                //     acc[formattedDate].push({
                //         title: "Booked",
                //         start: formattedDate,
                //         className: "event-booked",
                //     });
                // }
            });
            return acc;
        }, {});
    };

    useEffect(() => {
        if (availableSlots && availableSlots?.slots?.length > 0) {
            let filteredSlots = [];

            if (currentTab === "Default Slot") {
                filteredSlots = availableSlots?.slots?.filter(slot => slot?.isDefault);
                setFilteredSlot(filteredSlots)
            } else if (currentTab === "Custom Slot") {
                filteredSlots = availableSlots?.slots?.filter(slot => !slot?.isDefault);
                setFilteredSlot(filteredSlots)
            }

            if (filteredSlots?.length > 0) {
                const mappedEvents = mapAvailableDates(filteredSlots);
                const eventsList = Object.values(mappedEvents).flat();
                setEvents(eventsList);
            } else {
                setEvents([]);
            }
        }
    }, [availableSlots, currentTab]);


    // Fetch slots on component mount
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchCurrentUserSlots();
    }, []);


    const handleToggleUpdate = async (value) => {
        const toastId = toast.loading('updating please wait...')
        let res
        if (availableSlots?.slots?.length === 0) {
            res = await createDefaultSlot()
        } else {
            res = await updateDefaultSlotCreationStatus(value)
        }
        if (res?.success) fetchCurrentUserSlots()
        toast.dismiss(toastId)
    };



    return (
        <div>
            {pageIndex === 1 &&
                <MeetingDashboard
                    availableSlots={availableSlots}
                    handleUpdatePageIndex={() => setPageIndex(2)}
                    handleToggleUpdate={(value) => handleToggleUpdate(value)}
                    toggleBtnStatus={availableSlots?.defaultSlot?.isEnabled || false}
                />
            }
            {pageIndex === 2 &&
                <>
                    {/* <div className="flex items-center justify-center w-full mt-5">
                        {['Default Slot', 'Custom Slot'].map((item, index) => (
                            <div key={index} onClick={() => setCurrentTab(item)} className={`p-2 px-4 cursor-pointer font-semibold ${currentTab === item ? 'text-brandRed bg-red-100 rounded-3xl' : 'text-black'}`}>{item}</div>
                        ))}
                    </div> */}
                    <div className="flex justify-between w-full h-full">
                        <Calender events={events} />
                        <DateAndTimeCard handleSubmitted={fetchCurrentUserSlots} availableSlots={filteredSlot || []} />
                    </div>

                    {currentTab === 'Default Slot' &&

                        <div className="rounded-lg p-6 border border-gray-300 max-w-[400px] cursor-pointer ms-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center justify-between w-full">
                                    <span className="text-base font-semibold">
                                        Default Timeslot {availableSlots?.defaultSlot?.isEnabled ? "ON" : "OFF"}
                                    </span>
                                    <ToggleWithText
                                        isChecked={availableSlots?.defaultSlot?.isEnabled}
                                        onToggle={() => handleToggleUpdate(!availableSlots?.defaultSlot?.isEnabled)}
                                    />
                                </div>
                            </div>

                            <p className="mt-2 text-sm text-subText" >
                                Other members can now view your meeting slots, making it easier for them to schedule meetings with you at a convenient time.
                            </p>
                        </div>
                    }

                    <div className="flex items-center justify-center w-full my-8">
                        <button
                            onClick={() => setPageIndex(1)}
                            className="px-4 py-2 font-semibold border rounded-lg border-brandRed text-brandRed">
                            Back
                        </button>
                    </div>
                </>
            }
        </div>
    );
};

export default Index;
