import React, { Suspense, lazy, useEffect } from 'react'

import Banner from './components/banner'
import { getFeaturedEvents, getUpcomingEvents } from 'apis/siteIntro/eventHome'
import { Helmet } from 'react-helmet'

const TagBasedEvents = lazy(() => import('./components/tagBasedEvents'))
const HostWithUs = lazy(() => import('./components/hostWithUs'))
const EventCategory = lazy(() => import('./components/eventCategory'))
const EventsList = lazy(() => import('./components/EventsList'))
const SocialMedia = lazy(() => import('./components/socialMedia'))

function Index() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>

            <Helmet Helmet >
                <meta name='title' content="Connect through Couple Squad Events | App & Web Versions" />
                <meta name='description' content="Join Couple Squad's events community! Explore 20+ interest-based categories, meet people in person or virtually, and connect through unique Couple Squad events." />
                <link rel="canonical" href="https://www.couplesquad.com/event" />
            </Helmet >


            {/* Main Banners */}
            <div div className="overflow-hidden" >
                <Banner />
            </div >
            {/* Interest Based Events */}
            <div div className="mt-10" >
                <Suspense children={<TagBasedEvents />} fallback={<div>loading...</div>} />
            </div >

            {/* HostWithUs */}
            <div div className="bg-[#f5f5f5]" >
                <Suspense children={<HostWithUs />} fallback={<div>loading...</div>} />
            </div >

            {/* EventCategory */}
            <div div className="mt-10" >
                <Suspense children={<EventCategory />} fallback={<div>loading...</div>} />
            </div >

            {/* UpComing Events */}
            <div div className="mt-16" >
                <Suspense children={<EventsList mainText={'Upcoming Events'} api={getUpcomingEvents} />} fallback={<div>loading...</div>} />
            </div >

            {/* Activities  */}
            <div div className="mt-16" >
                <Suspense children={<EventsList mainText={'Activities'} api={getFeaturedEvents} />} fallback={<div>loading...</div>} />
            </div >

            {/* SocialMedia  */}
            <div div className="mt-16" >
                <Suspense children={<SocialMedia />} fallback={<div>loading...</div>} />
            </div >


        </div >
    )
}

export default Index