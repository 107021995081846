import React from 'react'
const ghostMode = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/Popups/ghostMode_ahjzzf`


function Index({ closeModal }) {
    return (
        <div>
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="max-w-md p-6 mx-auto text-center bg-white rounded-lg shadow-lg">
                    {/* Icon */}
                    <div className="flex justify-center my-5">
                        <img src={ghostMode} alt="ghostMode" />
                    </div>
                    <div className="w-[80%] mx-auto">
                        {/* Title */}
                        <h2 className="mb-2 text-2xl font-semibold">
                            Turn Off Ghost Mode for Connection request
                        </h2>

                        {/* Description */}
                        <p className="mb-6 text-gray-600">
                            To use the GPS feature, please switch off Ghost Mode and get back on track!
                        </p>

                        {/* Button */}
                        <button
                            className="w-full px-4 py-2 text-white transition bg-blue-500 rounded-md hover:bg-blue-600"
                            onClick={closeModal}
                        >
                            DONE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index