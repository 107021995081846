const getCloudinaryImageBaseUrl = (path) =>
    `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}${path}`;

const iconsBaseUrl = 'CoupleSquad%20Static%20Assets/Icons/'
const navBarAndSideBaseUrl = 'CoupleSquad%20Static%20Assets/Navbar_and_sidebar/'

// ========= GOOGLE PLAY AND PLAY STORE  ========= //
export const googlePlayLabelWhite = getCloudinaryImageBaseUrl(`${iconsBaseUrl}Google Play and Play store/dpufuvzw3zzpmi5ka6uk`)
export const googlePlayLabelBlack = getCloudinaryImageBaseUrl(`${iconsBaseUrl}Google Play and Play store/d5iqv4cigam2iqs1orgv`)
export const appStoreLabelWhite = getCloudinaryImageBaseUrl(`${iconsBaseUrl}Google Play and Play store/jfig3qzs7rqjrduydcwu`)
export const appStoreLabelBlack = getCloudinaryImageBaseUrl(`${iconsBaseUrl}Google Play and Play store/xo7ahtzdysv7pdhc7jbx`)

// ========= COUPLE SQUAD LOGOS  ========= //
export const coupleSquadLogoWhite = getCloudinaryImageBaseUrl('CoupleSquad Static Assets/Couple_Squad_Logs/s4spdobbaznhht3ps4iv')
export const coupleSquadLogoColor = getCloudinaryImageBaseUrl('CoupleSquad Static Assets/Couple_Squad_Logs/dbdc5poz0my5fzfacklb')

// ========= AVATAR  ========= //
export const maleAvatar = getCloudinaryImageBaseUrl(`${iconsBaseUrl}Avatar/Avatarmale_gcfimp`)
export const femaleAvatar = getCloudinaryImageBaseUrl(`${iconsBaseUrl}Avatar/Avatar_female_botypm`)

// ========= SOCIAL MEDIA ICONS  ========= //
export const socialMediaBlackIcons = {
    facebook: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/facebook_zjnlfp`),
    instagram: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/instagram_xqi5xe`),
    whatApp: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/whatsApp_jmaabl`),
    twitter: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/twitter_z9ttc0`),
    youtube: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/youtube_lguwhr`),
    linkedIn: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/linkedIn_jp2h7g`),
}

export const socialMediaWhiteIcons = {
    facebook: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/facebook_d36otz`),
    instagram: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/instagram_uxgzks`),
    twitter: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/twitter_hs4vhr`),
    youtube: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/youTube_cztb5s`),
    linkedIn: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/linkedIn_umhqw9`),
    pinterest: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/pinterest_aziagi`),
    medium: getCloudinaryImageBaseUrl(`${iconsBaseUrl}social_media/medium_rxzydj`),
}


// ========= Authenticated Navbar Active and Inactive ICONS  ========= //
export const authenticatedNavbarIcons = {
    active: {
        home: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/homeActive_gzyaoo`),
        match: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/discoverActive_ansyjy`),
        connection: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/connectionActive_grgqm3`),
        chat: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/chatActive_go8vpr`),
        notification: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/notificationsActive_bv4dwe`),
    },

    inactive: {
        home: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/homeInactive_oobm5u`),
        match: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/discoverInactive_ucqhza`),
        connection: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/connectionInactive_eougs4`),
        chat: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/chatInactive_e2fdg1`),
        notification: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/notificationsInactive_occ6nm`),
    },
    filter: getCloudinaryImageBaseUrl(`${navBarAndSideBaseUrl}auth_nav/filter_zz9noo`)
}