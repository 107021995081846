import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CONNECTIONS } from '../../../shared/router';
import { useDispatch, useSelector } from 'react-redux';
import { guidPosition } from 'utils/helper';
import { setPosition } from '../../../redux/slice/guid';

function Index({ count = false }) {
    const handPickRef = useRef(null);
    const dispatch = useDispatch();

    const currentStep = useSelector((state) => state.guide.currentStep);
    const currentUser = useSelector((state) => state.userProfile);

    const tabs = [
        { label: "Friends", location: CONNECTIONS.FRIENDS },
        { label: "Handpicked", location: CONNECTIONS.HANDPICK },
        { label: "Events", location: CONNECTIONS.EVENTS },
        { label: "Requested", location: CONNECTIONS.REQUESTS },
    ]

    if (currentUser?.personal?.relationship_status === 'couple') {
        tabs.splice(1, 1);
    }

    const [selectedTab, setSelectedTab] = useState('Handpicked')
    const location = useLocation();

    useEffect(() => {
        const currentSidebarItem = tabs.find(item => item.location === location.pathname);
        if (currentSidebarItem) {
            setSelectedTab(currentSidebarItem.label);
        } else {
            setSelectedTab(undefined);
        }
    }, [location]);

    useEffect(() => {
        const updatePosition = () => {
            const additionalPosition = { top: 0, left: 80, }
            if (handPickRef.current && currentStep === 7) {
                guidPosition(handPickRef, dispatch, setPosition, additionalPosition)
            }
        };

        // Initial calculation and recalculate on window resize
        updatePosition();
        window.addEventListener('resize', updatePosition);
        return () => window.removeEventListener('resize', updatePosition);
    }, [dispatch, currentStep]);

    return (
        <div>
            <div className='flex flex-wrap gap-1 my-5 md:gap-2 w-fit'>
                {tabs.map((item, index) => (

                    <div ref={item?.label === 'Handpicked' ? handPickRef : null} key={index} className={`w-fit p-1 px-3 text-base md:text-xl
                        ${selectedTab === item.label ? 'text-white bg-brandRed rounded-3xl' : 'text-black'} 
                        ${currentStep === 7 && item?.label === 'Handpicked' ? 'z-[110] bg-white rounded-xl border-none p-2' : ''}`}
                    >
                        <Link className='cursor-pointer' to={item.location} onClick={(e) => {
                            !item.location && e.preventDefault()
                            setSelectedTab(item.label)
                        }}>
                            {selectedTab === item.label ? `${item.label} ${count ? `(${count})` : ''}` : item.label}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default React.memo(Index)