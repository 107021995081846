import React, { useEffect, useRef, useState } from 'react';
import FeatureCard from './featureCard ';
import { CONVERSATION, EVENTS, GPS, WALLET } from 'shared/router';

// Assets
import statusIcon from 'assets/home/icons/status.png'
import meetingIcon from 'assets/home/icons/meetingIcon.png'
import nearestUserIcon from 'assets/home/icons/nearestUser.png'
import scannerIcon from 'assets/home/icons/scanner.png'
import qrCodeIcon from 'assets/home/icons/qrCode.png'
import walletIcon from 'assets/home/icons/wallet.png'
import walletSecondaryIcon from 'assets/home/icons/walletSecondary.png'
import currentPlan from 'assets/home/icons/currentPlan.svg'
import coins from 'assets/home/icons/coins.svg'
import coupons from 'assets/home/icons/coupons.svg'

import { getUserDetailsForHome } from 'apis/home';
import { capitalize } from 'shared/textFormats';
import { convert24To12Hour, formatDate } from 'shared/dates';
import { Link } from 'react-router-dom';
import { getFeaturedEvents } from 'apis/siteIntro/eventHome';

import { EventOverviewCard } from 'components/Cards/event'
import { useDispatch, useSelector } from 'react-redux';
import { setPosition } from '../../../../redux/slice/guid';
import { guidPosition } from 'utils/helper';

function Index() {
    const activityPlanRef = useRef(null);
    const activityCoinsRef = useRef(null);
    const activityCouponRef = useRef(null);
    const dispatch = useDispatch();
    const currentStep = useSelector((state) => state.guide.currentStep);
    const [userInfo, setUserInfo] = useState()
    const [featuredEvents, setFeaturedEvents] = useState([])

    const featuresData = [
        // {
        //     icon: statusIcon, title: 'Affinity Score', link: '#',
        //     secondaryIcon: statusIcon, secondaryTitle: userInfo?.affinity_score, secondaryLink: '#', textColor: 'text-blue-500', bgColor: 'bg-blue-100'
        // },
        {
            icon: meetingIcon, title: 'Meeting', link: CONVERSATION.MEETING_SLOTS,
            secondaryIcon: meetingIcon, secondaryTitle: 'Meeting', secondaryLink: CONVERSATION.MEETING_SLOTS, textColor: 'text-blue-500', bgColor: 'bg-blue-100'
        },
        {
            icon: nearestUserIcon, title: 'Find Nearest User', link: GPS.GPS,
            secondaryIcon: nearestUserIcon, secondaryTitle: 'GPS', secondaryLink: GPS.GPS, textColor: '', bgColor: 'bg-[#dce2f0]'
        },
        {
            icon: walletIcon, title: 'My Wallet', link: WALLET.WALLET,
            secondaryIcon: walletSecondaryIcon, secondaryTitle: `${userInfo?.wallet?.last_transaction_type} ${userInfo?.wallet?.last_transaction}`, secondaryLink: WALLET.WALLET, textColor: 'text-green-500', bgColor: 'bg-green-100'
        },
        {
            icon: scannerIcon, title: 'Scan QR', link: WALLET.SCAN,
            secondaryIcon: qrCodeIcon, secondaryTitle: 'Show QR', secondaryLink: WALLET.CARD, textColor: 'text-purple-500', bgColor: 'bg-purple-100'
        },
    ]


    const fetchUserDetails = async () => {
        const res = await getUserDetailsForHome()
        if (res?.success) setUserInfo(res?.data)
    }

    const handleFeaturedEvents = async () => {
        const res = await getFeaturedEvents()
        if (res?.success) setFeaturedEvents(res?.data)
    }


    useEffect(() => {
        handleFeaturedEvents()
        fetchUserDetails()
    }, [])

    const activityContent = [
        { label: 'Plans', value: userInfo?.wallet?.current_plan, icon: currentPlan, refValue: activityPlanRef },
        { label: 'Coins', value: userInfo?.wallet?.balance, icon: coins, refValue: activityCoinsRef },
        { label: 'Coupons', value: userInfo?.total_offers, icon: coupons, refValue: activityCouponRef },
    ]

    useEffect(() => {
        const updatePosition = () => {
            const isMobile = window.innerWidth <= 768;
            const mobilePosition = { top: -300, left: 140 };
            const desktopPosition = { top: -380, left: 60 };
            const additionalPosition = isMobile ? mobilePosition : desktopPosition;

            if (activityPlanRef.current && currentStep === 1) {
                guidPosition(activityPlanRef, dispatch, setPosition, additionalPosition)
            } else if (activityCoinsRef.current && currentStep === 2) {
                const mobilePosition = { top: -390, left: -20 };
                const additionalPosition = isMobile ? mobilePosition : desktopPosition;
                guidPosition(activityCoinsRef, dispatch, setPosition, additionalPosition)
            } else if (activityCouponRef.current && currentStep === 3) {
                const mobilePosition = { top: -450, left: 50 };
                const additionalPosition = isMobile ? mobilePosition : desktopPosition;
                guidPosition(activityCouponRef, dispatch, setPosition, additionalPosition)
            }
        };

        // Initial calculation and recalculate on window resize
        updatePosition();
        window.addEventListener('resize', updatePosition);
        return () => window.removeEventListener('resize', updatePosition);
    }, [dispatch, currentStep]);


    return (
        <div className="w-full p-6 mx-auto text-black">
            {/* Header Section */}
            <div className="flex flex-col items-center justify-between p-6 text-white rounded-lg bg-gradient-to-r from-pink-500 to-purple-500 sm:flex-row">
                <div>
                    <h2 className="text-2xl font-semibold">Welcome back, {userInfo?.name}!</h2>
                    <p className="mt-1 text-sm">Always stay updated in Couple Squad Profile</p>
                </div>
                <div className="mt-4 sm:mt-0">
                    <img
                        src={userInfo?.photo}
                        alt="Profile"
                        className="object-cover w-20 h-20 rounded-full"
                    />
                </div>
            </div>

            {featuredEvents?.length > 0 &&
                <>
                    <div className="text-[26px] font-semibold mt-10">Featured Events</div>
                    <div className="flex flex-wrap gap-4 mt-4">
                        {featuredEvents?.map((item) => (
                            <div key={item.id} className="w-full my-2 lg:w-[300px] max-h-[310px]">
                                <Link to={EVENTS.EVENT_DETAILS.replace(':slug', item.slug)} className="block h-full">
                                    <EventOverviewCard
                                        img={item.image}
                                        eventName={capitalize(item?.title)}
                                        fromToTime={`${formatDate(item.start_date)} ${convert24To12Hour(item.start_time)} to`}
                                        fromToDate={`${formatDate(item.end_date)} ${convert24To12Hour(item.end_time)}`}
                                        EventPrice={item.isFree ? true : item?.price}
                                        isBooked={item?.isBooked}
                                    />
                                </Link>
                            </div>
                        ))}
                    </div>
                </>
            }

            {/* Activity Section */}
            <div className="text-[26px] font-semibold mt-20 mb-4">Activity</div>
            <div className="bg-[#202635] h-full md:h-[120px] flex flex-wrap text-white text-xl w-full rounded-2xl items-center justify-around">
                {activityContent?.map((item, index) => (
                    <div key={index} className={`flex text-lg p-5 ${currentStep === index + 1 && 'bg-[#282b34] z-[110] rounded-full'}`} ref={item?.refValue}>
                        <img src={item?.icon} alt="" className='w-10 h-10' />
                        <div className="ms-2">
                            {item?.label}
                            <div>
                                {item?.value}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Feature Section */}
            <h2 className="mt-6 mb-4 text-2xl font-bold">Features</h2>
            <div className="flex flex-col justify-between space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                {featuresData?.map((feature, index) => (
                    <div className="w-full md:w-[30%] min-h-[130px]" key={index}>
                        <FeatureCard
                            icon={feature?.icon}
                            title={feature?.title}
                            link={feature?.link}
                            secondaryTitle={feature?.secondaryTitle}
                            secondaryIcon={feature?.secondaryIcon}
                            secondaryLink={feature?.secondaryLink}
                            textColor={feature?.textColor}
                            bgColor={feature?.bgColor}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Index;
