import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PROFILE_EDIT } from "shared/router";

const Index = ({ closeBtn }) => {
    const userInfo = useSelector((state) => state.userProfile);

    return (
        <div className="flex items-center justify-center">
            <div className="relative p-6 bg-white rounded-lg shadow-lg w-80">
                {/* Close Icon */}
                <button
                    className="absolute text-gray-500 top-4 right-4 hover:text-gray-700"
                    onClick={closeBtn} // Call the close function if passed
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>

                {/* Profile Image */}
                <div className="flex justify-center mb-4">
                    <div className="w-16 h-16 overflow-hidden border-2 border-gray-300 rounded-full">
                        <img
                            src={userInfo?.photo}
                            alt="Profile"
                            className="object-cover w-full h-full"
                        />
                    </div>
                </div>

                {/* Title */}
                <h2 className="text-xl font-semibold text-center text-gray-800">
                    Complete Your Profile
                </h2>

                {/* Progress Bar */}
                <div className="mt-4">
                    <div className="flex items-center justify-between mb-1 text-sm text-gray-500">
                        <span>Progress</span>
                        <span>{userInfo?.profile_completion}%</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div
                            className="bg-brandRed h-2.5 rounded-full"
                            style={{ width: `${userInfo?.profile_completion}%` }}
                        ></div>
                    </div>
                </div>

                {/* Description */}
                <p className="mt-4 text-sm text-center text-gray-600">
                    Your profile isn't complete yet! A minimum of 80% completion is
                    required to send and receive connections and access handpicked
                    matches. Complete your profile now for smooth interactions and
                    personalized matches!
                </p>

                {/* Button */}
                <div className="flex items-center justify-center w-full mt-6">
                    <Link to={PROFILE_EDIT.BASIC} className="px-6 py-2 font-medium text-white rounded-lg w-fit bg-brandRed hover:bg-red-600">
                        Done
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Index;
