import React from 'react'
const notEligible = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/Popups/notEligible_jtamce`

function Index({ description, handleClose }) {

    return (
        <div className='relative flex flex-col items-center justify-center px-5 text-black bg-white rounded-md shadow-md'>
            <button
                className='absolute px-2 py-1 text-black rounded-full cursor-pointer top-2 right-2'
                onClick={handleClose}
            >
                X
            </button>
            <img src={notEligible} alt="Not Eligible" className='mt-6' />
            <h1 className='mt-8 mb-10 text-lg font-semibold'>Oops!! You're not Eligible</h1>
            <div className="px-5 mb-10 text-subText">{description}</div>
        </div>

    )
}

export default Index