import React from 'react'
import { Link } from 'react-router-dom'
import { WALLET } from 'shared/router'
const insufficient = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/Popups/insufficient_a9kkpa`

function Index({ closeBtn }) {
    return (
        <div className='w-[336px] h-[398px] bg-white flex flex-col justify-center items-center m-4 border relative'>
            {closeBtn &&
                <button
                    onClick={closeBtn}
                    style={{ top: '10px', right: '10px' }}
                    className='absolute text-red-500 hover:text-red-700 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            }
            <div className='mb-2'>
                <img src={insufficient} alt='limits' className='w-[120px] h-[120px]  cursor-pointer' />
            </div>
            <div className='flex flex-col items-center justify-center gap-2'>
                <p className='text-black font-bold text-[18px]'>No Coins in Wallet</p>
                <p className='text-[14px] font-normal text-text_gray text-center w-[243px] h-[96px]'> Oops! It looks like your wallet is empty. Recharge now to continue enjoying our features.</p>
            </div>
            <div className='flex items-center justify-center'>
                <button className='w-[280px] h-[48px] bg-brandRed text-white cursor-pointer'>
                    <Link to={WALLET.RECHARGE} className='font-normal text-[16px]'>Continue</Link>
                </button>
            </div>
        </div>
    )
}

export default React.memo(Index)