import React, { useState, useEffect, lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet';

import { MainNavbar, MainNavbarResponsive } from 'components/NavbarAndTabs'
import frame2 from '../../../assets/DetailPage/frame2.svg'
import frame3 from '../../../assets/DetailPage/frame3.svg'
import clock from '../../../assets/DetailPage/Clock.svg'
import ageIcon from '../../../assets/DetailPage/ageIcon.svg'
import singlesGray from '../../../assets/DetailPage/singlesGray.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { getEventBySlug, getUnAuthEventBySlug, preBookTheEvent, saveAndUnSaveEvent } from '../../../apis/events'
import { determineBookingText } from '../../../shared/dates'
import { encodeToBase64 } from '../../../utils/encodeDecode';
import { capitalize } from 'shared/textFormats';
import { LazyImage } from 'components/image';
import NavbarUnAuth from "components/NavbarAndTabs/siteIntro"
import { calculateTotalHours, formatDate } from '../helper';

const footerBanner = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Event/rdixclk4jsgvfegygoe7`

const Description = lazy(() => import("./components/description"))
const Comments = lazy(() => import("./components/comments"))
const TermsAndConditions = lazy(() => import("./components/termsAndConditions"))
const LoginPopup = lazy(() => import("../../../components/Cards/login"))
const EventNotFound = lazy(() => import('components/Cards/emptyCards').then(module => ({ default: module.EventNotFound })));
const Footer = lazy(() => import("components/Footer/main/index"))
const LandingPageResponsiveFooter = lazy(() => import("components/Footer/main/responsive"))


function Index() {
  const navigate = useNavigate()

  const { slug } = useParams();
  const [data, setData] = useState(null)
  const [bookings, setBooking] = useState(null)
  const [isLoginPopUp, setIsLoginPopUp] = useState(false)
  const [session, setSession] = useState(false)
  const [isEventNotFoundPage, setIsEventNotFoundPage] = useState(false)
  const [currentTab, setCurrentTab] = useState('description')

  const fetchEventBySlug = async () => {
    const session = localStorage.getItem('session')
    const res = session ? await getEventBySlug(slug) : await getUnAuthEventBySlug(slug)
    if (res?.response?.data?.errno === 404) {
      setIsEventNotFoundPage(true)
    }
    setData({ ...res?.data?.event, access_to_view_attendees: res?.data?.access_to_view_attendees })
    const { event, ...data } = res?.data ?? {};
    setBooking(data)
  }

  const handleSaveAndUnSaveEvent = async (id) => {
    const res = await saveAndUnSaveEvent({ eventId: id })
    if (res?.success) {
      fetchEventBySlug()
    }
  }

  const bookingText = data?.start_date && data?.start_time && bookings ? determineBookingText(data, bookings) : ''

  const handleClick = async () => {
    if (session) {
      if (bookingText.includes('Pre-booking')) {
        const res = await preBookTheEvent(data?.id)
        if (res.success) {
          fetchEventBySlug()
        }
      } else if (bookingText === 'Book Now') {
        const id = encodeToBase64(data?.id)
        navigate(`/booking/${id}`)
      }
    } else {
      setIsLoginPopUp(true)
    }
  };

  useEffect(() => {
    fetchEventBySlug()
    setSession(localStorage.getItem('session'))
  }, [])


  return (

    <>
      {isEventNotFoundPage ?
        <Suspense children={<EventNotFound />} fallback={<div>loading...</div>} />
        :
        <>

          {data &&
            <div className='flex flex-col items-center h-full'>
              <Helmet>
                <title>{data?.meta_title}</title>
                <meta name="description" content={data?.meta_description} />
              </Helmet>
              {/* Navbar Section */}
              {session ?
                <div className="w-full mb-3 text-white h-18">
                  <div className="hidden lg:block">
                    <MainNavbar />
                  </div>
                  <div className="block lg:hidden">
                    <MainNavbarResponsive />
                  </div>
                </div>
                :
                <div className="w-full mb-3 text-white h-18">
                  <NavbarUnAuth isBlack={false} />
                </div>
              }

              {isLoginPopUp && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ">
                  <div className="p-6 rounded-lg shadow-lg ">
                    <Suspense fallback={<div>Loading...</div>} children={<LoginPopup cancel={() => setIsLoginPopUp(false)} />} />
                  </div>
                </div>
              )}
              <div className="w-full">
                <h1 className='text-start text-black font-medium text-2xl md:text-4xl mb-7 ml-[9%] lg:w-[50%]'>
                  {data?.title}
                </h1>
              </div>
              {/* Header Section */}
              <div className='flex flex-col items-center justify-between w-full px-4 mx-auto lg:px-0 lg:w-10/12 lg:flex-row'>

                {/* Main Content Section */}
                <div className='relative w-full lg:w-11/12 max-h-[440px] cursor-pointer'>
                  <span className={`absolute z-50 px-2 py-1 text-sm font-semibold text-white ${data?.mode === 'offline' ? 'bg-brandRed' : 'bg-green-500'}  rounded-md top-2 left-2`}> {data?.mode === 'offline' ? 'Offline' : 'Online'}</span>
                  <LazyImage className='object-cover w-full lg:w-11/12' src={data?.image} alt="Descriptive text" />
                  <div className="absolute bottom-0 left-0 p-2">
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleSaveAndUnSaveEvent(data?.id)}>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5588 29.6644L13.6692 33.907C13.1192 34.2284 12.4415 34.0009 12.1382 33.3931V33.3931C12.0505 33.2049 12.0031 32.9961 12 32.7836V13.9844C12 10.399 14.1796 8.96484 17.316 8.96484H24.684C27.7247 8.96484 30 10.3034 30 13.7453V32.7836C30 33.1228 29.8801 33.448 29.6668 33.6879C29.4535 33.9277 29.1641 34.0624 28.8624 34.0624C28.6699 34.059 28.4808 34.0058 28.3095 33.907L21.3774 29.6644C21.122 29.5092 20.8142 29.5092 20.5588 29.6644Z" stroke={data?.saved ? '#F83758' : 'white'} fill={data?.saved ? '#F83758' : ''} stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>


                {/* Event Details Section */}
                <div className='flex items-center w-full h-full md:h-[440px] md:w-1/2 mx-auto lg:w-5/12 border border-gray-400 rounded text-black'>
                  <div className="w-[75%] mx-auto">
                    <h1 className='flex items-start pt-2 text-xl font-medium md:mb-5 md:mt-7'>
                      {data?.title}
                    </h1>

                    <div className='flex items-start text-lg font-semibold md:text-4xl'>
                      {data?.isFree || data?.isFreemium ? 'Free' : `₹ ${data?.price}`}
                    </div>
                    <div className={`w-[98%] md:w-72 h-16 ${(bookingText === 'Already Pre Booked' || bookingText === 'Already Booked') ? 'bg-[#3E45EB]' : 'bg-brandRed'} text-white text-sm font-bold flex justify-center my-5 cursor-pointer`}>
                      <p className='flex items-center justify-center' onClick={handleClick}>
                        {bookingText}
                      </p>
                    </div>


                    <div className="flex flex-col mb-3 space-y-2 w-72 md:mb-0">
                      {/* Hours */}
                      {data?.start_date && data?.start_time && data?.end_date && data?.end_time &&
                        <p className='flex items-center'>
                          <img src={frame3} alt='coupleSquad' className='me-2' />
                          {calculateTotalHours(data?.start_date, data?.start_time, data?.end_date, data?.end_time)} Hr
                        </p>
                      }
                      {/* RelationShip */}
                      <p className='flex items-center'>
                        <img src={data?.relationship_status === 'single' ? singlesGray : frame2} alt='coupleSquad' className='me-2' />
                        {capitalize(data?.relationship_status)}
                      </p>
                      {/* Date */}
                      {data?.start_date && data?.end_date &&
                        <p className='flex items-center'>
                          <img src={clock} alt='coupleSquad' className='me-2' />
                          {formatDate(data?.start_date)} - {formatDate(data?.end_date)}
                        </p>
                      }
                      {/* Age */}
                      <p className='flex items-center mt-3'>
                        <img src={ageIcon} alt='coupleSquad' className='me-2' />
                        {data?.age_from} - {data?.age_to}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex flex-col w-full px-4 mx-auto lg:w-10/12 lg:px-0 lg:flex-row'>
                <div className='flex flex-col gap-4 lg:w-11/12'>
                  <div className='flex flex-row gap-4 mt-16 '>
                    <p className={`font-medium text-base ${currentTab === 'description' ? 'text-black' : 'text-base text-gray-500'} cursor-pointer`} onClick={() => setCurrentTab('description')}>Description</p>
                    {data?.enable_comments &&
                      <p className={`font-medium text-base ${currentTab === 'comments' ? 'text-black' : 'text-base text-gray-500'} cursor-pointer`} onClick={() => setCurrentTab('comments')}>Comments</p>
                    }
                    <p className={`font-medium text-base ${currentTab === 'Terms_and_conditions' ? 'text-black' : 'text-base text-gray-500'} cursor-pointer`} onClick={() => setCurrentTab('Terms_and_conditions')}>Terms And Conditions</p>
                  </div>
                  {currentTab === 'description' &&
                    <Suspense children={<Description data={data} />} />
                  }
                  {currentTab === 'comments' &&
                    <Suspense children={<Comments data={data} bookings={bookings} />} />
                  }
                  {currentTab === 'Terms_and_conditions' &&
                    <Suspense children={<TermsAndConditions data={bookings?.event_terms_and_conditions} />} />
                  }

                </div>

                <div className='flex flex-col w-full gap-5 mt-5 mb-16 md:w-1/2 md:mx-auto h-4/5 lg:w-5/12 l lg:mt-0'>
                  {/* <div className='z-10 mt-5'>
                    {data?.mode === 'offline' &&
                      <Map latitude={data?.lattitude} longitude={data?.longitude} />
                    }
                  </div> */}
                </div>

              </div>

              <img src={footerBanner} alt="" className='flex flex-col w-full px-4 mx-auto md:mt-8 lg:w-10/12 lg:px-0 lg:flex-row' />

              {/* Footer Section */}
              <div className='flex justify-center w-full mt-5'>
                <Suspense fallback={<div>Loading...</div>}>
                  <div className="hidden w-full bg-black lg:block">
                    <Footer />
                  </div>
                  <div className="block bg-black lg:hidden">
                    <LandingPageResponsiveFooter />
                  </div>
                </Suspense>
              </div>
            </div>
          }
        </>}
    </>
  )
}

export default Index