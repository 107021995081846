import React from 'react'
export const emptyEvents = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/Empty_Cards/emptyEvents_e0siax`

function Index({ mainText, subText }) {
    return (
        <div className='relative flex flex-col items-center justify-center w-full h-full m-4'>

            <div>
                <img src={emptyEvents} alt='EventImage' className='w-[124px] h-[160px] mb-2 cursor-pointer' />
            </div>
            <div className='flex flex-col items-center justify-center gap-2'>
                {mainText &&
                    <p className='text-black font-medium text-[18px]'>{mainText}</p>
                }
                {subText &&
                    <p className='text-[14px] font-normal text-text_gray text-center w-[243px] h-[96px]'>{subText}</p>
                }
            </div>
        </div>
    )
}

export default React.memo(Index)
