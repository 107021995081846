import React from 'react'
const noConnections = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/Empty_Cards/noConnections_n904yd`

function Index({ title = 'No Suggestions', decryption }) {
    return (
        <div className='w-[336px] h-[398px] bg-white flex flex-col justify-center items-center m-4'>
            <div>
                <img src={noConnections} alt='limits' className='w-[180px] h-auto mb-2' />
            </div>
            <div className='flex flex-col items-center justify-center gap-2'>
                <p className='text-black font-medium text-[18px]'>{title}</p>
                <p className='text-[14px] font-normal text-text_gray text-center w-[200px] h-[96px]'>{decryption}</p>
            </div>
        </div>
    )
}

export default React.memo(Index)