import React, { useEffect, useState } from 'react';
import { getAllNotifications } from '../../apis/filter';
import { timeAgo } from '../../shared/dates';
import { acceptConnectionRequest, rejectConnectionRequest } from '../../apis/connections';
import pdLogo from 'assets/pdLogo.png'
import { LazyImage } from 'components/image';
import { acceptContactInfo, rejectContactInfo } from 'apis/contact';



function Index({ handleClose }) {

    const [notifications, setNotifications] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 1,
        next_page: null,
    })

    const fetchAllTheNotifications = async (page) => {
        const res = await getAllNotifications(page)
        setNotifications(prev => ([...prev, ...res?.data]))
        setPagination({ ...res?.pagination })
    }

    useEffect(() => {
        fetchAllTheNotifications(1)
    }, [])

    const handleConnectionRequestAction = async (action, id) => {
        let res
        if (action === 'accept') {
            res = await acceptConnectionRequest(id)
        } else if (action === 'reject') {
            res = await rejectConnectionRequest(id)
        }
        if (res?.success) {
            setNotifications([])
            fetchAllTheNotifications(pagination?.current_page)
        }
    }

    const handleContactInfoAction = async (action, id) => {
        let res
        if (action === 'accept') {
            res = await acceptContactInfo(id)
        } else if (action === 'reject') {
            res = await rejectContactInfo(id)
        }
        if (res?.success) {
            setNotifications([])
            fetchAllTheNotifications(pagination?.current_page)
        }
    }


    return (

        <div className="max-w-md mx-auto overflow-hidden rounded-lg shadow-lg bg-white">
            <div className="relative flex items-center p-4 my-4">
                <h2 className="text-[20px] font-semibold text-black text-center flex-1">Notifications</h2>
                <div className="absolute top-0 right-0 p-2 mt-6 mr-2 text-xl leading-none text-black cursor-pointer" onClick={handleClose}>&#x2715;</div>
            </div>
            <div className="flex flex-col h-[80vh] overflow-hidden">
                <div className="flex flex-col flex-grow overflow-y-auto">
                    {notifications?.map((item, index) => (
                        <div key={index} className="flex flex-col p-4 space-x-4 text-black">

                            <div className="flex">
                                <LazyImage src={item?.member?.photo || pdLogo} alt={'no profile pic'} className="w-[3.5rem] h-[3.5rem] rounded-full" />
                                <div className="flex-1 ms-2">
                                    <div className="flex flex-col w-full">
                                        <div className="flex items-center justify-between w-full">
                                            <p className="text-sm">{item?.member?.name}</p>
                                            <p className="text-xs text-gray-500">{timeAgo(item?.createdAt)}</p>
                                        </div>
                                        <p className="mt-1 text-xs text-gray-500">{item?.body}</p>
                                    </div>
                                </div>
                            </div>

                            {item?.action_enabled &&
                                <div className="flex justify-center mt-2 space-x-2">
                                    <button className="px-6 py-2 text-gray-700 border" onClick={() => {
                                        if (item?.type === 'connection_request') {
                                            handleConnectionRequestAction('reject', item?.actionId)
                                        } else if (item?.type === 'contact_request') {
                                            handleContactInfoAction('reject', item?.actionId)
                                        }
                                    }}>Reject</button>
                                    <button className="px-6 py-2 text-white bg-red-500 rounded" onClick={() => {
                                        if (item?.type === 'connection_request') {
                                            handleConnectionRequestAction('accept', item?.actionId)
                                        } else if (item?.type === 'contact_request') {
                                            handleContactInfoAction('accept', item?.actionId)
                                        }
                                    }}>Accept</button>
                                </div>
                            }
                        </div>


                    ))}
                    {!pagination.next_page &&
                        <p className='mb-10 text-base text-center text-blue-500 cursor-pointer'></p>
                    }
                    {pagination.next_page &&
                        <p className='mb-10 text-base text-center text-blue-500 cursor-pointer' onClick={() => fetchAllTheNotifications(pagination.next_page)}>see more</p>
                    }
                </div>
            </div>
        </div>


    )
}

export default React.memo(Index);