import React from 'react'
const img = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/Popups/block_jnt3r4`

function Index({ closeBtn }) {


    return (
        <div>
            <div className="flex flex-col max-h-[70%] bg-white">
                <div className="flex flex-1 overflow-hidden bg-[#f9f9f9]">
                    {/* <button onClick={closeBtn} className="absolute top-0 right-0 p-2 mt-2 text-xl leading-none text-black me-3" >&#x2715;</button> */}
                    <div className="flex items-center justify-center flex-1 my-10">
                        <div className="w-[550px] flex flex-col items-center justify-center bg-white text-black rounded-lg">

                            <h1 className='mb-1 text-2xl font-semibold me-2'>Account Deactivated</h1>

                            <img src={img} alt="" className="mt-7" />

                            <p className='mt-7 w-[80%] text-center text-sm text-subText px-2'> Your account has been deactivated due to violation of our community Guidelines</p>

                            <h1 className='mt-8 text-subText'>Please contact our admin <br /><strong className='text-black'>“ hello@couplesquad.com”</strong></h1>

                            <div className="flex justify-around w-full px-10 mt-10 text-center">
                                <button onClick={closeBtn} className='w-full px-5 py-2 mx-2 text-white transition-colors rounded bg-brandRed hover:bg-red-700'>Done</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default React.memo(Index)