import React from 'react';
import { getTheApp } from 'utils/getTheApp';

const bgImage = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/siteIntro/Event%20Home/vsjv7ppumkgdvxp0rcnk`

function Index() {
    return (
        <div
            className="relative flex flex-col items-center justify-center h-screen text-white bg-center bg-cover"
            style={{
                backgroundImage: `url(${bgImage})`,
                background: "cover",
            }}
        >

            {/* Content */}
            <div className="relative z-10 max-w-3xl p-8 text-center bg-black mt-44 opacity-70">
                <h2 className="mb-2 font-medium text-md md:text-lg">
                    Connect with an Indian Professionals
                </h2>
                <h1 className="mb-4 text-xl font-bold md:text-5xl">
                    Couple <span className="text-red-500">S</span>quad
                </h1>
                <p className="mb-6 text-sm md:text-lg">
                    Couple Squad has dedicated our space for interactions among Indian
                    professionals. Let's meet fellow professionals through Couple Squad
                    events!
                </p>
            </div>

            <div onClick={getTheApp}
                className="p-3 transition duration-300 cursor-pointer md:p-6 md:font-semibold bg-brandRed mt-14 hover:bg-red-600">
                Download The App →
            </div>
        </div>
    );
};


export default React.memo(Index);
