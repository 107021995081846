import React from 'react';

function Index({ index, title, description, image, onSkip, onNext }) {
    return (
        <div className="relative w-full p-4 md:p-6 rounded-lg text-white flex flex-col justify-between h-[400px] md:h-[500px]">
            {/* Background image */}
            <img
                src={image}
                alt=""
                className="absolute inset-0 rounded-lg z-0 object-cover w-full h-full"
            />

            {/* Overlay content */}
            <div className="relative z-10">
                {/* Top Section */}
                <>
                    {title &&
                        <div className="bg-red-600 text-[12px] md:text-[14px] px-3 py-1 rounded-full w-fit font-semibold mt-2 md:mt-4 ml-2 md:ml-4">
                            {title}
                        </div>
                    }

                    {/* Content Section */}
                    {description &&
                        <div className="mt-4 px-2 md:px-4">
                            <p
                                className={`font-bold ${index === 0
                                    ? 'text-3xl md:text-6xl'
                                    : 'text-sm md:text-base'} max-w-[90%] md:max-w-[580px]`}
                            >
                                {description}
                            </p>
                        </div>
                    }
                </>
            </div>

            {/* Actions at the bottom */}
            {index !== 4 &&
                <div className="flex justify-between items-center px-4 md:px-6 mt-4 md:mt-0">
                    <button
                        onClick={onSkip}
                        className="text-xs md:text-sm text-white opacity-70 hover:opacity-100 hover:underline cursor-pointer"
                    >
                        Skip
                    </button>

                    <button
                        onClick={onNext}
                        className="p-2 bg-white z-10 rounded-full shadow-md focus:outline-none"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-4 h-4 md:w-5 md:h-5 text-black"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                    </button>
                </div>
            }
        </div>
    );
}

export default Index;
