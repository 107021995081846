import React, { Suspense, lazy, useState } from 'react';
import SideBar from "../../../components/sidebars/settings";
import phone from "../../../assets/settings/icons/Phone.svg";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
import { deleteAccount, sendOtpToEmail, sendOtpToPhone, updateEmailId, updatePhoneNo } from '../../../apis/settings';
import { useNavigate } from 'react-router-dom';
import { SITE_INTO } from '../../../shared/router';
import { resetUserProfile } from '../../../redux/slice/userProfile';
const DeletePopup = lazy(() => import("components/popUps").then(module => ({ default: module.DeleteAccount })))

function Index() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userProfile);

    const [deletePopup, setDeletePopup] = useState(false)

    const [emailText, setEmailText] = useState(null)
    const [phoneText, setPhoneText] = useState(null)

    const [phoneOtp, setPhoneOtp] = useState(null)
    const [emailOtp, setEmailOtp] = useState(null)

    const [changeOpen, setChangeOpen] = useState({
        email: false,
        phone: false
    });
    const [verifyOpen, setVerifyOpen] = useState({
        email: false,
        phone: false
    });

    const handleChangeEmail = () => {
        setChangeOpen({ email: true, phone: false })
    }

    const handleVerifyEmail = async () => {
        if (emailText) {
            const toastId = toast.loading("sending OTP...")
            const res = await sendOtpToEmail({ email: emailText })
            if (res?.success) {
                setVerifyOpen({ email: true, phone: false })
            }
            toast.dismiss(toastId)
        } else {
            toast.error('Please Enter EmailId')
        }
    }

    const handleSubmitEmail = async () => {
        if (emailOtp) {
            const toastId = toast.loading("Updating Email...")
            const res = await updateEmailId({ new_email: emailText, otp: emailOtp })
            if (res.success) {
                setEmailText(res?.data?.email)
            }
            toast.dismiss(toastId)
        } else {
            toast.error("Enter OTP")
        }
    }

    const handleChangePhone = () => {
        setChangeOpen({ phone: true, email: false })
    }

    const handleVerifyPhone = async () => {
        if (phoneText) {
            const toastId = toast.loading("sending OTP...")
            const res = await sendOtpToPhone({ phone: phoneText })
            if (res?.success) {
                setVerifyOpen({ phone: true, email: false })
            }
            toast.dismiss(toastId)
        } else {
            toast.error("Please Enter Phone No")
        }
    }

    const handleSubmitPhone = async () => {
        if (phoneOtp) {
            const toastId = toast.loading("Updating Phone No...")
            const res = await updatePhoneNo({ new_phone: phoneText, otp: phoneOtp })
            toast.dismiss(toastId)
            if (res.success) {
                setEmailText(res?.data?.phone)
            }
        } else {
            toast.error("Enter OTP")
        }
    }

    const handleDeleteAccount = async (reason) => {
        const res = await deleteAccount({ reason })
        if (res?.success) {
            localStorage?.removeItem('session')
            dispatch(resetUserProfile());
            navigate(SITE_INTO.LANDING)
        }
    }

    return (
        <div className="flex flex-col h-full min-h-screen">

            {deletePopup &&
                <div className="absolute inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-75">
                    <Suspense children={<DeletePopup handleClose={() => setDeletePopup(false)} handleDelete={(value) => handleDeleteAccount(value)} />} />
                </div>
            }

            <div className="flex flex-col w-full text-black">
                <div className="flex-1 w-full p-4 overflow-auto md:m-10 md:w-10/12 lg:ms-14">
                    <div className="flex items-center justify-between border-b border-gray-300 ">
                        <h1 className="my-5 text-2xl font-semibold">Privacy settings</h1>
                        <h1 className="p-2 px-3 my-5 text-base font-semibold border cursor-pointer text-brandRed border-brandRed" onClick={() => setDeletePopup(true)}>Delete Account</h1>
                    </div>

                    <div className="flex flex-wrap justify-around mt-20 lg:flex-nowrap">
                        {/* Change Email */}
                        <div className="w-full">
                            <p className="mb-2">Email</p>
                            <div className="h-14 w-full md:w-[400px] border-2 border-gray-300 rounded-md flex items-center justify-between">
                                <label className="md:ms-2">{userInfo?.email}</label>
                                <button className="p-2 px-4 md:me-2 rounded-md border-2 border-black text-[12px]" onClick={handleChangeEmail}>Change</button>
                            </div>
                            {changeOpen.email && (
                                <div className="h-14 w-full md:w-[400px] border-2 border-gray-300 rounded-md flex items-center justify-between mt-2">
                                    <input type="text" className="ms-2" placeholder="Enter new email" onChange={(e) => setEmailText(e.target.value)} />
                                    {emailText &&
                                        <button className="p-2 px-4 me-2 rounded-md border-2 border-black text-[12px]" onClick={handleVerifyEmail}>Verify</button>
                                    }
                                </div>
                            )}
                            {verifyOpen.email && (
                                <div className="h-14 w-full md:w-[400px] border-2 border-gray-300 rounded-md flex items-center justify-between mt-2">
                                    <input type="text" className="ms-2" placeholder="Enter OTP" onChange={(e) => setEmailOtp(e.target.value)} />
                                    <button className="p-2 px-4 me-2 rounded-md border-2 border-black text-[12px]" onClick={handleSubmitEmail}>Submit</button>
                                </div>
                            )}
                        </div>
                        {/* Change Phone */}
                        <div className="w-full">
                            <p className="mb-2">Phone</p>
                            <div className="h-14 w-full md:w-[400px] border-2 border-gray-300 rounded-md flex items-center justify-between">
                                <div className="flex items-center ms-2">
                                    <img src={phone} alt="" />
                                    <label className="ms-2" >{userInfo?.phone}</label>
                                </div>
                                <button className="p-2 px-4 me-2 rounded-md border-2 border-black text-[12px]" onClick={handleChangePhone}>Change</button>
                            </div>
                            {changeOpen.phone && (
                                <div className="h-14 w-full md:w-[400px] border-2 border-gray-300 rounded-md flex items-center justify-between mt-2">
                                    <input type="text" className="ms-2" placeholder="Enter new phone number" onChange={(e) => setPhoneText(e.target.value)} />
                                    {phoneText &&
                                        <button className="p-2 px-4 me-2 rounded-md border-2 border-black text-[12px]" onClick={handleVerifyPhone}>Verify</button>
                                    }
                                </div>
                            )}
                            {verifyOpen.phone && (
                                <div className="h-14 w-full md:w-[400px] border-2 border-gray-300 rounded-md flex items-center justify-between mt-2">
                                    <input type="text" className="ms-2" placeholder="Enter OTP" onChange={(e) => setPhoneOtp(e.target.value)} />
                                    <button className="p-2 px-4 me-2 rounded-md border-2 border-black text-[12px]" onClick={handleSubmitPhone}>Submit</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
