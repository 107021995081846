import React from "react";

import calenderIcon from 'assets/meetings/icons/dashboard/calender.svg'
import handShakeIcon from 'assets/meetings/icons/dashboard/handShake.svg'
import remainderIcon from 'assets/meetings/icons/dashboard/remainder.svg'
import { Link } from "react-router-dom";
import { CONNECTIONS, CONVERSATION } from "shared/router";
import { useSelector } from "react-redux";

const Index = ({ availableSlots, handleUpdatePageIndex }) => {
    const userInfo = useSelector((state) => state.userProfile);

    return (
        <div className="w-[90%] mx-auto min-h-screen text-black">
            {/* Header Section */}
            <div className="my-8">
                <h1 className="text-2xl font-semibold">Hi {userInfo.name}</h1>
                <p className="text-subText">Welcome to Meeting Scheduling</p>
            </div>

            {/* Cards Section */}
            <div className="flex flex-col gap-4 md:flex-row">
                {/* Card 1 */}
                <div onClick={handleUpdatePageIndex} className="rounded-lg p-6 border border-gray-300 max-w-[400px] cursor-pointer">
                    <img src={calenderIcon} alt="calenderIcon" />

                    <h2 className="my-2 text-lg font-semibold" >Create Your Slots</h2>
                    <p className="text-sm text-subText" >
                        The first step is to create your available meeting slots. After that, you can schedule meetings and access your meeting history.
                    </p>
                </div>

                <Link
                    to={availableSlots?.slots?.length === 0 ? "#" : `${CONNECTIONS.FRIENDS}?type=meeting`}
                    className={`relative shadow-md rounded-lg p-6 border border-gray-300 max-w-[400px] 
                    ${availableSlots?.slots?.length === 0 ? "pointer-events-none cursor-not-allowed" : ""}`}
                >
                    {availableSlots?.slots?.length === 0 && (
                        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 rounded-lg"></div>
                    )}
                    <img src={handShakeIcon} alt="handShakeIcon" />
                    <h2 className="my-2 text-lg font-semibold">Schedule Meeting</h2>
                    <p className="text-sm text-subText">
                        You can set up online or offline meetings with friends on your list. If you both have matching available times, scheduling the meeting will be easy.
                    </p>
                </Link>

                {/* Card 3 */}
                <Link
                    to={availableSlots?.slots?.length === 0 ? "#" : CONVERSATION.MEETING_MANAGEMENT}
                    className={`relative shadow-md rounded-lg p-6 border border-gray-300 max-w-[400px] 
                    ${availableSlots?.slots?.length === 0 ? "pointer-events-none cursor-not-allowed" : ""}`}
                >
                    {availableSlots?.slots?.length === 0 && (
                        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 rounded-lg"></div>
                    )}
                    <img src={remainderIcon} alt="remainderIcon" />
                    <h2 className="my-2 text-lg font-semibold">Meeting Request & History</h2>
                    <p className="text-sm text-subText">
                        View your past and upcoming meetings here, along with all the relevant details.
                    </p>
                </Link>

            </div>
        </div>
    );
};

export default Index;
