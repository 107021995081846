import React from "react";
import banner from 'assets/siteIntro/appDownload/image/banner.png'
import { Link } from "react-router-dom";
import { appStoreLabelBlack, googlePlayLabelBlack } from "assets/common/cloudinary";

const Index = () => {
    return (
        <div className="relative flex flex-col items-center justify-center h-full min-h-screen px-6 py-8 text-black lg:flex-row lg:justify-between md:px-12 md:py-12 bg-gradient-to-r from-blue-100 to-pink-100">
            {/* Text Section */}
            <div className="flex flex-col items-center max-w-lg text-center lg:items-start lg:text-left">
                <p className="mb-2 text-sm font-semibold uppercase">Explore</p>
                <h1 className="mb-4 text-2xl font-bold leading-tight sm:text-3xl lg:text-5xl">
                    No More Matrimony. <br />
                    No More Dating. Let’s <span className="text-pink-500">Datrimony!</span>
                </h1>
                {/* App Buttons */}
                <div className="flex items-center justify-center my-6 space-x-4 lg:justify-start lg:my-8">
                    <Link to={"https://apps.apple.com/us/app/couple-squad/id6624305594"}>
                        <img src={appStoreLabelBlack} alt="Datrimony" className="h-10 sm:h-12" />
                    </Link>
                    <Link to={"https://play.google.com/store/apps/details?id=com.couplesquad.app"}>
                        <img src={googlePlayLabelBlack} alt="Datrimony" className="h-10 sm:h-12" />
                    </Link>
                </div>
            </div>

            {/* Image Section */}
            <div className="absolute bottom-0 right-0">
                <img
                    src={banner}
                    alt="App Preview"
                    className="w-fit md:w-[500px] lg:w-[600px] xl:w-[700px] h-auto"
                />
            </div>
        </div>

    );
};

export default Index;
