import React, { useEffect, useState } from "react";
import requestReciveIcon from 'assets/meetings/icons/requestReciveIcon.svg'
import requestSentIcon from 'assets/meetings/icons/requestSentIcon.svg'

function Index({ name, location, method, onAccept, onDecline, onPreview, photo, isSend }) {
    const [descriptionContent, setDescriptionContent] = useState(false)

    useEffect(() => {
        if (onAccept) {
            if (isSend) {
                setDescriptionContent("waiting for (his/her) Approval")
            } else {
                setDescriptionContent("waiting for your Approval")
            }
        }
    }, [])

    return (
        <div className="flex flex-col items-center justify-between p-4 mb-4 rounded-lg shadow-md">

            <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                    <img
                        src={photo}
                        alt={name}
                        className="w-12 h-12 mr-4 rounded-full"
                    />
                    <div>
                        <h3 className="text-lg font-semibold">{name}</h3>
                        <p className="text-sm">Mode : <span className="font-semibold text-green-600">{method}</span></p>
                        <p className="text-gray-500">{location}</p>
                    </div>
                </div>
            </div>

            <div className="flex justify-end w-full space-x-2">
                {onAccept && !isSend &&
                    <button
                        className="p-2 px-4 text-black border border-black rounded-full"
                        onClick={onDecline}
                    >
                        Decline
                    </button>
                }
                {onAccept && !isSend &&
                    <button
                        className="p-2 px-6 text-white rounded-full bg-brandRed"
                        onClick={onAccept}
                    >
                        Accept
                    </button>
                }
            </div>
            <div className="flex items-end justify-end w-full mt-2 me-8">
                <button onClick={onPreview} className="border-b-2 border-black text-subtext">Preview</button>
            </div>


            <div className="flex items-center justify-between w-full mt-4">
                <div className="text-subText">{descriptionContent && descriptionContent}</div>
                <img src={isSend ? requestSentIcon : requestReciveIcon} alt="coupleSquad" />
            </div>
        </div>
    );
};

export default Index;
