import React from 'react';
import { LazyImage } from 'components/image';

const bgImage = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/siteIntro/eea9efbxe9ggmexgjzuc`

function Index() {
    return (
        <div className="relative w-full h-full">
            {/* Background Image */}
            <LazyImage
                src={bgImage}
                className="w-full h-full "
            />

            {/* Centered Text */}
            <div className="absolute inset-0 flex items-center justify-center">
                <h1 className="text-white text-9xl">Blogs</h1>
            </div>
        </div>
    );
}

export default Index;
