import React, { lazy, Suspense, useEffect } from 'react';
import Banner from "./components/banner"
import { Helmet } from 'react-helmet';


const VisionAndMission = lazy(() => import("./components/visionAndMission"))
const BenefitOfConnectingCS = lazy(() => import("./components/benefitOfConnectingCS"))
const TeamMembers = lazy(() => import("./components/teamMembers"))
const WhoWeAre = lazy(() => import("./components/whoWeAre"))
const OurMilestone = lazy(() => import("./components/ourMilestone"))


function Index() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Helmet>
                <meta name='title' content="India’s only Datrimony and event-based social community" />
                <meta name='description' content="Join Couple Squad, India’s only Datrimony platform, and first event-based social community. Connect through curated events and build meaningful relationships." />
                <link rel="canonical" href="https://www.couplesquad.com/aboutUs" />
            </Helmet>


            {/* Main Banners */}
            <div className="min-h-screen pt-20">
                <Banner />
            </div>

            {/* Why signUp */}
            <>
                <Suspense children={<VisionAndMission />} fallback={<div>loading...</div>} />
            </>

            {/* Benefit Of Connecting Couples Squad */}
            <div className="bg-[#f4f8ff]">
                <Suspense children={<BenefitOfConnectingCS />} fallback={<div>loading...</div>} />
            </div>

            {/* TeamMembers */}
            <div className="w-[95%] md:w-full">
                <Suspense children={<TeamMembers />} fallback={<div>loading...</div>} />
            </div>

            {/* Who We Are */}
            <div className="mt-20">
                <Suspense children={<WhoWeAre />} fallback={<div>loading...</div>} />
            </div>

            {/* OurMilestone */}
            <div className="flex flex-col items-center justify-center h-screen ">
                <Suspense children={<OurMilestone />} fallback={<div>loading...</div>} />
            </div>


        </div>
    );
}

export default Index;