import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import { City } from 'country-state-city';
import { toast } from 'react-toastify'
import { Switch } from 'antd';

import { getAdvanceFilter, updateAdvanceFilter } from '../../../apis/filter';
import { fetchAllCastsByReligionId, fetchAllReligions, } from "../../../apis/religions"
import { DropDownSelector, OptionList, SelectAndView } from 'components/UI';

// Assets
import { dietDrinkingOptions, dietEatingOptions, dietSmokingOptions, educationOptions, singlesMartialStatusOPtions, workoutOptions, timeLineOptions, lookingForOptions, lookingForOptionsCouples } from 'utils/constant/user/profileInfo';
import verified from '../../../assets/discover/verified.svg';
import graduateIcon from '../../../assets/filter/icons/graduateIcon.svg'
import muscleIcon from '../../../assets/filter/icons/muscleIcon.svg'
import smokingIcon from '../../../assets/filter/icons/smokingIcon.svg'
import veganIcon from '../../../assets/filter/icons/veganIcon.svg'
import wineGlassIcon from '../../../assets/filter/icons/wineGlassIcon.svg'
import { capitalize } from '../../../shared/textFormats';

const PlanUpgrade = lazy(() => import("components/popUps").then(module => ({ default: module.PlanUpgrade })))


function Index({ closeSlide = false }) {

  const cites = City.getCitiesOfCountry('IN')

  const [profileVerified, setProfileVerified] = useState(false);
  const [relationShipStatus, setRelationShipStatus] = useState()
  const [currentPage, setCurrentPage] = useState(null);
  const [showPlanUpgradePopUp, setShowPlanUpgradePopUp] = useState(false)
  const [religionAndCastOptions, setReligionAndCastOptions] = useState({
    castOptions: [],
    religionOptions: []
  })

  const [userSelectedInfo, setUserSelectedInfo] = useState({
    verified_profile: true,
    looking_for: [],
    timeline: "",
    marital_status: [],
    workout: [],
    city: "",
    religion_id: [],
    caste_id: [],
    education: [],
    physical_status: "",
    eating: [],
    drinking: [],
    smoking: [],
    show_everyone: false,
  });

  const [showOptions, setShowOptions] = useState({
    upgradePlan: false,
  })

  const fetchAdvanceFilterData = async () => {
    const res = await getAdvanceFilter();

    const religionIds = res?.data?.response?.advanced?.religion_id || [];
    const ids = religionIds?.map(religion => religion._id);

    const cast = res?.data?.response?.advanced?.caste_id || [];
    const castIds = cast?.map(caste_id => caste_id._id);
    setUserSelectedInfo({ ...res?.data?.response?.advanced, religion_id: ids, caste_id: castIds });
    setRelationShipStatus(res?.data?.response?.userId?.personal?.relationship_status);

    if (ids.length > 0) fetchAllCastByReligionId(ids)
    if (res?.data?.advanced_preferences_access === false) setShowOptions(prev => ({ ...prev, upgradePlan: true }))
  };

  const getAllReligions = async () => {
    const res = await fetchAllReligions();
    setReligionAndCastOptions(prev => ({ ...prev, religionOptions: res?.data }))
  };

  const fetchAllCastByReligionId = async (ids) => {
    let allCastOptions = [];
    for (const id of ids) {
      const res = await fetchAllCastsByReligionId(id);
      allCastOptions = [...allCastOptions, ...res?.data];
    }

    setReligionAndCastOptions(prev => ({
      ...prev,
      castOptions: [...allCastOptions]
    }));
  }


  useEffect(() => {
    fetchAdvanceFilterData()
    getAllReligions()
  }, [showOptions.upgradePlan]);

  const updateUserSelectedInfo = useCallback((key, value) => {
    if (showOptions.upgradePlan) {
      setShowPlanUpgradePopUp(true)
    } else {
      setUserSelectedInfo(currentState => {
        return { ...currentState, [key]: value };
      });
    }
  }, [])

  // Update to api
  const handleSaveBtn = async () => {
    const toastId = toast.loading('Applying Filter')
    const res = await updateAdvanceFilter(userSelectedInfo)
    toast.dismiss(toastId);
    if (res?.success) toast.success("Updated Filter")
    if (closeSlide) closeSlide()
  }

  const resetFilter = () => {
    setUserSelectedInfo({
      verified_profile: true,
      looking_for: [],
      timeline: "",
      marital_status: [],
      workout: [],
      city: "",
      religion_id: [],
      caste_id: [],
      education: [],
      physical_status: "",
      eating: [],
      drinking: [],
      smoking: [],
      show_everyone: false,
    })
  }

  // Configuration for dynamic component rendering
  const pageComponents = [
    {
      label: "Relationship Status",
      path: 'marital_status',
      options: singlesMartialStatusOPtions,
      couples: false
    },
    {
      label: "Looking For",
      path: 'looking_for',
      options: relationShipStatus === 'single' ? lookingForOptions : lookingForOptionsCouples,
      couples: true
    },
    // {
    //   label: "Physical Status",
    //   path: 'physical_status',
    //   options: physicalStatusOptions,
    //   couples: true,
    //   icons: healthIcons
    // },
    {
      label: "Eating",
      path: 'eating',
      options: dietEatingOptions,
      couples: false,
      icons: veganIcon
    },
    {
      label: "Smoking",
      path: 'smoking',
      options: dietSmokingOptions,
      couples: false,
      icons: smokingIcon
    },
    {
      label: "Workout",
      path: 'workout',
      options: workoutOptions,
      couples: false,
      icons: muscleIcon
    },
    {
      label: "Drinking",
      path: 'drinking',
      options: dietDrinkingOptions,
      couples: false,
      icons: wineGlassIcon
    },
    {
      label: "Education",
      path: 'education',
      options: educationOptions,
      couples: true,
      icons: graduateIcon
    },
  ];

  const dropDownComponent = [
    {
      label: 'City',
      selectedValue: userSelectedInfo.city,
      placeholderValue: "Select City",
      options: cites?.map(city => ({ label: city?.name, name: city?.name })),
      path: 'city',
      couples: true
    },
    {
      label: 'Religion',
      selectedValue: userSelectedInfo?.religion_id,
      placeholderValue: "Select Religion",
      options: religionAndCastOptions?.religionOptions?.map(religion => ({ label: religion?.id, name: religion?.name })),
      path: 'religion_id',
      couples: false
    },
    {
      label: 'Caste',
      selectedValue: userSelectedInfo?.caste_id,
      placeholderValue: "Select Caste",
      options: religionAndCastOptions?.castOptions ? religionAndCastOptions?.castOptions?.map(cast => ({ label: cast?.id, name: cast?.name })) : [],
      path: 'caste_id',
      couples: false
    },
    {
      label: 'Timeline',
      selectedValue: userSelectedInfo.timeline,
      placeholderValue: "Select Timeline",
      options: timeLineOptions?.map(timeLine => ({ label: timeLine?.value, name: timeLine?.label })),
      path: 'timeline',
      couples: false
    },
  ]

  return (
    <div className='relative w-full'>

      {showPlanUpgradePopUp &&
        <div className="absolute inset-0 z-50 flex items-center justify-center w-full h-full bg-opacity-75">
          <Suspense children={<PlanUpgrade closeBtn={() => setShowPlanUpgradePopUp(false)} />} fallback={<div>loading...</div>} />
        </div>
      }

      {currentPage ? (
        <div className='flex flex-col items-center justify-center'>
          {pageComponents.filter(({ label }) => label === currentPage).map(({ label, path, options, icons }) => (
            <>
              <div className='flex items-center gap-4'>
                <img src={icons} alt="" />
                <h1 className='text-2xl font-bold text-black'>{label}</h1>
              </div>
              <OptionList
                key={label}
                selectedOptions={userSelectedInfo[path]}
                availableOptions={options}
                handleOnclick={(value) => updateUserSelectedInfo(path, value)}
              />
            </>
          ))}
          <div className="flex items-start justify-start w-full pt-4 mt-10">
            <button className="block w-full p-3 text-white border rounded-sm ps-4 bg-brandRed" onClick={() => setCurrentPage(null)}>
              Save
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className='font-semibold text-[21px] text-black'>Advanced Filters</div>
          {/* Verification  Section */}
          <div className='flex items-center justify-center w-full mb-5 border-2 border-gray-300 rounded cursor-pointer h-28 '>
            <div className='flex items-center justify-center md:gap-14'>
              <img src={verified} alt='verification' className='w-10 h-10 md:w-14 md:h-14' />
              <div>
                <p className='text-base text-black md:font-medium md:text-2xl'>Show verified Profiles only</p>
              </div>
              <Switch checked={profileVerified} onClick={() => setProfileVerified(prev => !prev)} className={profileVerified ? 'bg-gray-700' : 'bg-gray-400'} />
            </div>
          </div>
          {pageComponents.map(({ label, path, couples }, index) => {
            return (
              <>
                {((relationShipStatus === 'single') || (relationShipStatus === 'couple' && couples))
                  &&
                  <>
                    {path === 'education' && (
                      <div className="education-heading">
                        <div className="my-2 text-xl font-semibold text-black">Education Preference</div>
                        {/* <div className="my-2 text-sm text-gray-5000">Make changes to your account</div> */}
                      </div>
                    )}
                    <SelectAndView
                      key={label}
                      label={label}
                      selectedValue={capitalize(userSelectedInfo[path])}
                      onLabelClick={() => {
                        if (showOptions.upgradePlan) {
                          setShowPlanUpgradePopUp(true)
                        } else {
                          setCurrentPage(label)
                        }
                      }}
                    />
                  </>
                }
              </>
            );
          })}

          {dropDownComponent.map(({ label, selectedValue, placeholderValue, options, path, couples }, index) => {
            return (
              <>
                {((relationShipStatus === 'single') || (relationShipStatus === 'couple' && couples))
                  &&
                  <>
                    {path === 'city' && (
                      <div className="education-heading">
                        <div className="my-2 text-xl font-semibold text-black">Location Preference</div>
                        {/* <div className="my-2 text-sm text-gray-5000">Make changes to your account</div> */}
                      </div>
                    )}
                    {path === 'religion_id' && (
                      <div className="education-heading">
                        <div className="my-2 text-xl font-semibold text-black">Religion Preference</div>
                      </div>
                    )}
                    <DropDownSelector
                      label={label}
                      selectedValue={selectedValue}
                      placeholderValue={placeholderValue}
                      options={options}
                      mode={(path === 'religion_id' || path === 'caste_id') && 'multiple'}
                      handleOnChange={(value) => {
                        updateUserSelectedInfo(path, value)
                        if (path === 'religion_id') fetchAllCastByReligionId(value)
                      }}
                    />
                  </>
                }
              </>
            );
          })}

          {/* {relationShipStatus === 'couple' &&
            <>
              <h1 className='my-4 text-lg text-black'>Looking From</h1>
              <div className="flex items-center justify-around w-full border border-gray-300 h-28">
                <p className='text-black'>looking from my gender only</p>
                <Switch checked={userSelectedInfo?.show_everyone} onChange={() => updateUserSelectedInfo("show_everyone", !userSelectedInfo?.show_everyone)} className={userSelectedInfo?.show_everyone ? 'bg-gray-700' : 'bg-gray-400'} />
              </div>
            </>
          } */}

          {/* Save BTN */}
          <div className="h-[70px] w-full mt-5 flex items-center justify-between">
            <button
              className={`w-[45%] h-[60px] ${showOptions.upgradePlan ? 'bg-[#fed7de] pointer-events-none cursor-not-allowed text-white' : 'cursor-pointer border border-black text-black'}  flex justify-center items-center relative`}
              onClick={resetFilter}
            >
              Reset
            </button>
            <button
              className={`w-[45%] h-[60px] ${showOptions.upgradePlan ? 'bg-[#fed7de] pointer-events-none cursor-not-allowed' : 'bg-brandRed cursor-pointer'} flex justify-center items-center text-white relative`}
              onClick={showOptions.upgradePlan ? undefined : handleSaveBtn}
            >
              Save
            </button>

          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(Index);