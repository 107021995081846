import React, { lazy, Suspense, useEffect, useState } from "react";
import SlotSelection from "./components/slotSelection";
import { useParams } from "react-router-dom";
import { decodeFromBase64 } from "utils/encodeDecode";
import { getAvailableSlotsOfEndUser } from "apis/meeting";

const TimeAndModeSelection = lazy(() => import("./components/timeAndModeSelection"))
const OfflineMeetingPlaceSelection = lazy(() => import("./components/offlineMeetingPlaceSelection"))
const OfflineMeetingPlaceDetail = lazy(() => import("./components/offlineMeetingPlaceDetail"))
const SuccessPopup = lazy(() => import("./components/meetingRequestSuccessPopup"))
const FinalConfirmationWithPrev = lazy(() => import("./components/finalConfirmationWithPrev"))

function Index() {
    const { id } = useParams();

    const [availableSlots, setAvailableSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTimeAndMode, setSelectedTimeAndMode] = useState({
        selectedTimeSlot: null,
        selectedMode: null,
    });
    const [selectedPlaceId, setSelectedPlaceId] = useState(null);
    const [filteredTimes, setFilteredTimes] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);

    // Fetch slots from API
    const fetchSlots = async () => {
        const res = await getAvailableSlotsOfEndUser(decodeFromBase64(id));
        if (res?.success) {
            setAvailableSlots([...res?.data?.slots?.customSlots]);
        }
    };

    // Handle moving to the next page and set the selected date
    const handleNext = (data, index) => {
        if (pageIndex === 1) {
            setSelectedDate(data[0]);
        } else if (pageIndex === 2) {
            setSelectedTimeAndMode({ ...data })
        } else if (pageIndex === 3) {
            setSelectedPlaceId(data)
        }
        setPageIndex(index);
    };

    // When `selectedDate` changes, filter available times
    useEffect(() => {
        if (selectedDate) {
            const selectedSlot = availableSlots.find(
                (slot) => new Date(slot.date).toISOString().split("T")[0] === selectedDate
            );
            setFilteredTimes(selectedSlot?.times || []); // Update times based on selected date
        }
    }, [selectedDate, availableSlots]);

    useEffect(() => {
        fetchSlots();
    }, []);


    const handleBackFinalPage = () => {
        if (selectedTimeAndMode.selectedMode === 'offline') {
            setPageIndex(4)
        } else {
            setPageIndex(2)
        }
    }

    return (
        <div className="flex flex-col items-center justify-center w-full h-full min-h-screen bg-white">

            {pageIndex === 1 && (
                <div className="mt-5">
                    <SlotSelection data={availableSlots} handleNext={(data, index) => handleNext(data, index)} />
                </div>
            )}
            {pageIndex === 2 && (
                <div className="mt-5">
                    <Suspense fallback={<div>loading...</div>}>
                        <TimeAndModeSelection
                            availableTimes={filteredTimes}
                            handleNext={(data, index) => handleNext(data, index)}
                            handleBack={() => setPageIndex(pageIndex - 1)}
                        />
                    </Suspense>
                </div>
            )}
            {pageIndex === 3 && (
                <div className="w-full mt-5">
                    <Suspense fallback={<div>loading...</div>}>
                        <OfflineMeetingPlaceSelection
                            userId={id}
                            selectedTime={selectedTimeAndMode?.selectedTimeSlot}
                            handleNext={(data, index) => handleNext(data, index)}
                            handleBack={() => setPageIndex(pageIndex - 1)}
                        />
                    </Suspense>
                </div>
            )}

            {pageIndex === 4 && (
                <div className="w-full mt-5">
                    <Suspense fallback={<div>loading...</div>}>
                        <OfflineMeetingPlaceDetail
                            placeId={selectedPlaceId}
                            handleBack={() => setPageIndex(pageIndex - 1)}
                            handleNext={(index) => handleNext("", index)}
                        />
                    </Suspense>
                </div>
            )}


            {/* FINAL */}
            {pageIndex === "final" && (
                <div className="mt-5">
                    <Suspense fallback={<div>loading...</div>}>
                        <FinalConfirmationWithPrev
                            userId={id}
                            selectedDate={selectedDate}
                            selectedPlaceId={selectedPlaceId}
                            selectedTime={selectedTimeAndMode?.selectedTimeSlot}
                            mode={selectedTimeAndMode?.selectedMode}
                            handleBack={handleBackFinalPage}
                            handleNext={() => setPageIndex('success')}
                        />
                    </Suspense>
                </div>
            )}
            {pageIndex === "success" && (
                <div className="mt-5">
                    <Suspense fallback={<div>loading...</div>}>
                        <SuccessPopup />
                    </Suspense>
                </div>
            )}
        </div>
    );
}

export default Index;
