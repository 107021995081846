import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

const CalendarComponent = ({ handleDateSelect, events }) => {


    return (
        <div className="w-full max-w-screen-lg p-4 mx-auto text-black">
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                selectable={true}
                select={handleDateSelect}
                events={events}
                headerToolbar={{
                    start: "title",
                    center: "",
                    end: "prev,next",
                }}
                height="auto"
            />
        </div>
    );
};

export default CalendarComponent;
