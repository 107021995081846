import React, { useState } from 'react'
import './custom.css';
import BasicFilter from '../basicFilter'
import AdvanceFilter from '../advanceFilter'
import { closeBtnBlack } from 'assets/common/icons';


function Index({ handleShowFilter }) {

  const [selectedPreference, setSelectedPreference] = useState('Basic');



  return (
    <div className='flex justify-center bg-white z-[120]'>
      {/* preferences - advanced & basic */}
      <div className='flex w-[650px] h-screen overflow-hidden bg-white z-[120]'>
        <div className='w-[650px] flex flex-col justify-start  overflow-y-auto'>

          <div className='flex justify-end items-end z-[120]'>
            <div className='flex items-center justify-center px-4 mx-auto mt-10'>
              <div className='w-[179px] h-[29px] flex justify-center items-center'>
                <p className='font-medium text-[24px] text-black'>Narrow Search</p></div>
            </div>
            <div className='cursor-pointer' onClick={handleShowFilter} >
              <img src={closeBtnBlack} alt='close' className='max-w-[20px] max-h-[20px] me-4' /></div>
          </div>
          {/* Basic and Advance Tab */}
          <div className="flex items-center justify-center w-full mx-auto my-8">
            <div className="flex items-center justify-around w-4/5 mx-auto">
              <div className="flex flex-col items-start justify-center w-2/5 cursor-pointer space-around" onClick={() => setSelectedPreference('Basic')}>
                <p className={`w-full text-center font-medium text-[14px] ${selectedPreference === 'Basic' ? 'text-brandRed' : 'text-gray-500'}`}>Preference</p>
                <p className={`w-full text-center font-semibold text-[19px] ${selectedPreference === 'Basic' ? 'text-brandRed border-brandRed border-b-2 pb-2' : 'text-gray-500'}`}>Basic</p>
              </div>
              <div className="flex flex-col items-start justify-center w-2/5 cursor-pointer space-around" onClick={() => setSelectedPreference('Advanced')}>
                <p className={`w-full text-center font-medium text-[14px] ${selectedPreference === 'Advanced' ? 'text-brandRed' : 'text-gray-500'}`}>Preference</p>
                <p className={`w-full text-center font-semibold text-[19px] ${selectedPreference === 'Advanced' ? 'text-brandRed border-brandRed border-b-2 pb-2' : 'text-gray-500'}`}>Advanced</p>
              </div>
            </div>
          </div>

          {selectedPreference === 'Basic' ?
            <div className='flex justify-center '>
              <div className='w-[85%] flex flex-col justify-start  bg-white p-4'>
                <BasicFilter closeSlide={handleShowFilter} />
              </div>
            </div>

            :
            <div className='flex justify-center '>
              <div className='w-[85%] flex flex-col justify-start  bg-white p-4'>
                <AdvanceFilter closeSlide={handleShowFilter} />
              </div>
            </div>
          }


        </div>
      </div>
    </div >
  )
}
export default React.memo(Index)