import React, { useState } from "react";

const Index = ({ handleChange, selectedValue, options }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleOptionClick = (value) => {
        handleChange(value); // Call the parent-provided handler
        setDropdownOpen(false); // Close the dropdown after selection
    };

    return (
        <div className="relative w-full h-full">
            {/* Dropdown Trigger */}
            <button
                className="flex items-center justify-between w-full px-4 py-2 text-gray-700 bg-white border border-gray-400 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={() => setDropdownOpen((prev) => !prev)}
            >
                <div className="flex items-center">
                    {/* Active Icon */}
                    <img
                        src={
                            options.find((opt) => opt?.value === selectedValue)?.activeIcon
                        }
                        alt="Icon"
                        className="mr-2"
                    />
                    {/* Selected Label */}
                    <span className="text-red-500">
                        {options.find((opt) => opt?.value === selectedValue)?.label}
                    </span>
                </div>
            </button>

            {/* Dropdown Menu */}
            {dropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-md">
                    {options?.map((option) => (
                        <div
                            key={option?.value}
                            className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleOptionClick(option?.value)}
                        >
                            {/* Display Active or Inactive Icon */}
                            <img
                                src={
                                    option?.value === selectedValue
                                        ? option?.activeIcon
                                        : option?.inactiveIcon
                                }
                                alt="Icon"
                                className="mr-2"
                            />
                            {/* Label with dynamic color */}
                            <span
                                className={`${option?.value === selectedValue
                                    ? "text-red-500"
                                    : "text-black"
                                    }`}
                            >
                                {option?.label}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Index;
