import React from 'react';
import { Link } from 'react-router-dom';
import { socialMediaBlackIcons } from 'assets/common/cloudinary';

const TableOfContent = ({ toc }) => {
    const socialMedia = [
        { icon: socialMediaBlackIcons.linkedIn, link: "https://www.linkedin.com/company/couplesquad/" },
        { icon: socialMediaBlackIcons.facebook, link: "https://www.facebook.com/wearecouplesquad/" },
        { icon: socialMediaBlackIcons.instagram, link: "https://www.instagram.com/wearecouplesquad/" },
        { icon: socialMediaBlackIcons.youtube, link: "https://www.youtube.com/@wearecouplesquad/" },
        { icon: socialMediaBlackIcons.twitter, link: "https://twitter.com/wecouplesquad/" },
    ];

    const handleScrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            const offset = window.innerHeight * 0.1; // 10% of the viewport height
            const position = section.offsetTop - offset;

            window.scrollTo({
                top: position,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className="max-w-xs p-4 border rounded-lg">
            {/* Social Media Icons */}
            <div className="flex mb-4 space-x-4">
                {socialMedia.map((item, index) => (
                    <Link key={index} to={item?.link} className="text-blue-700">
                        <img src={item?.icon} alt="socialMediaIcons" />
                    </Link>
                ))}
            </div>

            {/* Table of Contents */}
            <div className='mb-20 text-black toc'>
                <h3 className="font-bold">Table of Contents</h3>
                <ul className="space-y-2">
                    {toc.map((item, index) => (
                        <li
                            key={item.id}
                            className="cursor-pointer hover:underline"
                            onClick={() => handleScrollToSection(item.id)}
                        >
                            {index + 1}. {item.text}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default TableOfContent;
