
import React from 'react';
import { getTheApp } from 'utils/getTheApp';

const bgImage = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad%20Static%20Assets/siteIntro/Host_With_Us/qeuqx0lwscxgpxxyasvq`
// const bgImage = `https://res.cloudinary.com/dilbksn0g/image/upload/f_auto,q_auto/v1/CoupleSquad%20Static%20Assets/siteIntro/Host_With_Us/qeuqx0lwscxgpxxyasvq`

function Index() {
    return (
        <div
            className="relative flex flex-col items-center justify-center h-screen text-white bg-center bg-cover"
            style={{
                backgroundImage: `url(${bgImage})`,
            }}
        >

            {/* Content */}
            <div className="relative z-10 max-w-3xl p-8 px-20 mt-32 text-center bg-black opacity-70">
                <h2 className="mb-2 text-2xl font-medium md:text-4xl">
                    Memorable Events
                </h2>
                <h1 className="mb-4 text-3xl font-bold text-white md:text-7xl">
                    Host With Us
                </h1>
            </div>

            <div onClick={getTheApp}
                className="p-3 transition duration-300 cursor-pointer md:p-6 md:font-semibold bg-brandRed mt-14 hover:bg-red-600">
                Download The App →
            </div>
        </div>
    );
};


export default React.memo(Index);

