import React, { useState, useEffect } from "react";
import deleteIcon from "assets/meetings/icons/delete.svg";
import { deleteSlot } from "apis/meeting";
import { toast } from "react-toastify";

// Used in the Date and Time Component 

function Index({ date, time = [], onSubmit }) {
    const [timeSlots, setTimeSlots] = useState([
        { startHour: "", startMinute: "", startPeriod: "", endHour: "", endMinute: "", endPeriod: "" },
    ]);

    useEffect(() => {
        if (time.length > 0) {
            const parsedTimeSlots = time.map((slot) => {
                const [startHour, startMinutePeriod] = slot.start.split(":");
                const [startMinute, startPeriod] = startMinutePeriod.split(" ");
                const [endHour, endMinutePeriod] = slot.end.split(":");
                const [endMinute, endPeriod] = endMinutePeriod.split(" ");
                return { startHour, startMinute, startPeriod, endHour, endMinute, endPeriod };
            });
            setTimeSlots(parsedTimeSlots);
        }
    }, [time]);

    const handleTimeChange = (index, field, value) => {
        const updatedSlots = [...timeSlots];
        updatedSlots[index][field] = value;
        setTimeSlots(updatedSlots);
    };

    const handleAddTimeSlot = () => {
        setTimeSlots([...timeSlots, { startHour: "", startMinute: "", startPeriod: "AM", endHour: "", endMinute: "", endPeriod: "AM" }]);
    };

    const handleRemoveSlot = async (index) => {
        // Extract the slot to be removed
        const slotToRemove = timeSlots[index];
        // Create the payload for the API call
        const payload = {
            date: date,
            startTime: `${slotToRemove.startHour}:${slotToRemove.startMinute} ${slotToRemove.startPeriod}`,
            endTime: `${slotToRemove.endHour}:${slotToRemove.endMinute} ${slotToRemove.endPeriod}`,
        };

        if (time.length !== 0) {
            const res = await deleteSlot(payload);
            if (res?.success) {
                toast.success('Removed the slot');
            } else {
                return;
            }
        }

        const updatedSlots = timeSlots.filter((_, i) => i !== index);
        setTimeSlots(updatedSlots);
    };


    const handleSubmit = () => {
        const transformedData = timeSlots.map((slot) => ({
            start: `${slot.startHour}:${slot.startMinute} ${slot.startPeriod}`,
            end: `${slot.endHour}:${slot.endMinute} ${slot.endPeriod}`,
        }));

        onSubmit(date, transformedData);
    };


    const renderTimeInput = (label, index, prefix) => (
        <div className="mb-4">
            <p className="mb-2 text-sm font-medium text-gray-600">{label}</p>
            <div className="flex items-center justify-between w-full">
                <div className="flex items-center space-x-2">
                    {["Hour", "Minute"].map((unit, i) => (
                        <div key={i} className="flex flex-col">
                            <input
                                type="text"
                                maxLength={2}
                                value={timeSlots[index][`${prefix}${unit}`]}
                                onChange={(e) => handleTimeChange(index, `${prefix}${unit}`, e.target.value)}
                                className="w-20 h-20 text-center cursor-pointer text-3xl bg-[#ededed] rounded-md focus:bg-white focus:border-red-500 focus:text-red-500"
                                placeholder={unit === "Hour" ? "HH" : "MM"}
                            />
                            <div className="text-[12px] text-subText">{unit}</div>
                        </div>
                    ))}
                </div>
                <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    className="w-6 h-6 cursor-pointer"
                    onClick={() => handleRemoveSlot(index)}
                />
                <div>
                    {["AM", "PM"].map((period) => (
                        <div
                            key={period}
                            onClick={() => handleTimeChange(index, `${prefix}Period`, period)}
                            className={`${timeSlots[index][`${prefix}Period`] === period
                                ? "bg-[#ededed] text-brandRed"
                                : "text-black bg-white"
                                } p-2 border cursor-pointer`}
                        >
                            {period}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    return (
        <div className="flex flex-col items-center justify-center w-full h-full p-6 text-black bg-gray-50">
            <div className="w-full max-w-lg p-4 bg-white border border-gray-300 rounded-lg shadow-md">
                {timeSlots.map((_, index) => (
                    <div key={index} className="mb-6">
                        {renderTimeInput("Beginning Time", index, "start")}
                        {renderTimeInput("End Time", index, "end")}
                    </div>
                ))}

                <div className="flex justify-between mt-4">
                    <button
                        onClick={handleAddTimeSlot}
                        className="px-4 py-2 font-bold text-white bg-blue-500 rounded-md hover:bg-blue-600"
                    >
                        Add
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="px-4 py-2 font-bold text-white bg-green-500 rounded-md hover:bg-green-600"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Index;
