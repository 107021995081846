import avatar from 'assets/avatar.png'

import {
    AccountBlocked,
    BlockAndReportUser,
    ConnectPlanPopUp,
    EventBookingSuccess,
    InsufficientCoins,
    LimitReached,
    LocationTurnoff,
    NodataAccess,
    NoMessage,
    PaymentSuccess,
    PlanUpgrade,
    SomethingWentWrong,
    ToVerifyAccount,
    PaymentCancellationReason,
    ProfileCompletionIsLessThan80
} from '..'

function Index() {

    const blockAndReportUserData = {
        name: "Demo User",
        photo: avatar
    }

    return (
        <div className='flex flex-wrap gap-2 w-fit'>
            <AccountBlocked />
            <BlockAndReportUser data={blockAndReportUserData} type={'block'} />
            <ToVerifyAccount />
            <PaymentSuccess />
            <EventBookingSuccess paymentTime={'2024-12-07T10:53:16.701Z'} eventName={'Demo'} senderName={'Demo User'} booking_id={'1234567890'} />

            {/* Alert Popups */}
            <PlanUpgrade />
            <NodataAccess />
            <LocationTurnoff />
            <InsufficientCoins />
            <LimitReached />
            <NoMessage />
            <SomethingWentWrong />
            <div className="w-full my-10">
                <ConnectPlanPopUp />
            </div>
            {/* <PaymentCancellationReason /> */}
            <ProfileCompletionIsLessThan80 />
        </div>
    )
}

export default Index