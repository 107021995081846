
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { nameAndDOBValidation } from "../../../utils/validations/onBoarding";
import { toast } from "react-toastify";
import * as Yup from 'yup'
import { fetchAllReligions } from "apis/editProfile";
import { addLabelAndValueInData } from "shared/textFormats";
import { DropDownSelector } from "components/UI";

const bg_img = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Auth/Onboarding/p6pcq0qc2nnhrfgoyilf`

function Index() {

    const navigate = useNavigate()
    const [religionOptions, setReligionOptions] = useState([])
    const [data, setData] = useState({
        name: null,
        dob: null,
        religion_id: null,
    });

    const updateDOB = (part, value) => {
        const [year, month, day] = data.dob ? data.dob.split('-') : ['', '', ''];
        const newDOB = {
            day: part === 'day' ? value : day,
            month: part === 'month' ? value : month,
            year: part === 'year' ? value : year,
        };
        setData(prevState => ({
            ...prevState,
            dob: `${newDOB.year}-${newDOB.month?.padStart(2, '')}-${newDOB.day?.padStart(2, '')}`
        }));
    };

    const [year, month, day] = data.dob ? data.dob.split('-') : ['', '', ''];


    const handleLetsGo = async () => {
        try {
            await nameAndDOBValidation.validate(data, { abortEarly: false });
            const serializedObject = localStorage.getItem('userData');
            const currentUserData = JSON.parse(serializedObject) || {};
            const updatedUserData = {
                ...currentUserData,
                onBoardingOne: true,
                data: {
                    ...currentUserData.data,
                    name: data?.name,
                    dob: data?.dob,
                    religion_id: data?.religion_id
                },
            };
            localStorage.setItem('userData', JSON.stringify(updatedUserData));
            navigate('/on-boarding-2');
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    const getAllReligion = async () => {
        const res = await fetchAllReligions()
        if (res?.success) {
            const labeledReligion = addLabelAndValueInData(res?.data, 'id', 'name')
            setReligionOptions(labeledReligion)
        }
    }

    useEffect(() => {
        const serializedObject = localStorage.getItem('userData');
        const userData = JSON.parse(serializedObject);
        if (userData?.onBoardingOne) {
            navigate('/on-boarding-2')
        } else if (userData?.data?.name) {
            setData({ name: userData?.data?.name, dob: userData?.data?.dob, religion_id: userData?.data?.religion_id })
        }
        getAllReligion()
    }, [])


    return (
        <div className="flex flex-col min-h-screen bg-white lg:flex-row">
            <div className="relative flex-1 hidden overflow-hidden bg-center bg-cover lg:block lg:h-auto h-1/2 lg:w-full">
                <img src={bg_img} alt="" className="object-cover w-full h-screen" />

                <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-50">
                    <h1 className="p-4 font-semibold text-7xl">The Real Story <br /> Begins With <br /> Couple Squad.</h1>
                    <p className="p-4">Finding something valuable isn't an easy task. We are aware of <br /> that, that's why Couple Squad is here.</p>
                </div>
            </div>


            <div className="relative flex flex-col items-center justify-center flex-1 w-full h-screen my-10 bg-white lg:h-auto lg:w-6/12 lg:my-0 text-brandRed ">
                <div className="w-[90%] mb-5 md:w-7/12 lg:mb-0">

                    <h1 className="text-3xl font-semibold md:text-5xl">Let’s build your <br /> profile!</h1>

                    <p className="mt-4 text-xl font-semibold text-black md:text-2xl">What’s your name?</p>
                    <input type="text" value={data?.name} placeholder="Enter your name" className="w-full px-4 py-2 mt-2 text-black bg-white border md:w-10/12"
                        onChange={(e) => setData((prev => ({ ...prev, name: e.target.value })))}
                    />
                    <p className="my-5 text-sm text-gray-500">Let's strengthen our bond even more.</p>

                    <h1 className="mt-4 text-2xl font-semibold text-black">Your Birthday Is Special For Us</h1>
                    <div className="flex space-x-2 text-black">
                        <input type="text" maxLength={2} value={day} placeholder="DD" className="w-[25%] md:w-[20%] p-2 text-sm bg-white border" onChange={(e) => updateDOB('day', e.target.value)} />
                        <input type="text" maxLength={2} value={month} placeholder="MM" className="w-[25%] md:w-[20%] p-2 text-sm bg-white border" onChange={(e) => updateDOB('month', e.target.value)} />
                        <input type="text" maxLength={4} value={year} placeholder="YYYY" className="w-[40%] md:w-[40%] p-2 text-sm bg-white border" onChange={(e) => updateDOB('year', e.target.value)} />
                    </div>

                    <p className="mt-4 text-xl font-semibold text-black md:text-2xl">Religion</p>
                    <div className="w-full md:w-10/12">
                        <DropDownSelector options={religionOptions}
                            handleOnChange={(value) => setData(prev => ({ ...prev, religion_id: value }))}
                            placeholderValue={'Choose Religion'}
                            selectedValue={data?.religion_id}
                        />
                    </div>

                    <button className="w-full p-3 mt-10 text-sm text-white border md:w-10/12 bg-brandRed" onClick={handleLetsGo}>
                        Let's Go
                    </button>
                </div>

                <div className="absolute inset-x-0 bottom-0 lg:bottom-10">
                    <div className="w-full bg-gray-200">
                        <div className="py-1 text-xs leading-none text-center text-white bg-brandRed" style={{ width: "14%" }}></div>
                    </div>
                </div>
            </div>


        </div>

    );
}

export default Index;