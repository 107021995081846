import EmptyEvents from "../emptyEvents"
import EventNotFound from "../eventNotFound"
import NoConnections from "../noConnections"
import NoMatches from "../noMatches"

function Index() {
    return (
        <div>
            <EmptyEvents />
            <EventNotFound />
            <NoConnections />
            <NoMatches />
        </div>
    )
}

export default Index